import React from 'react';
import { View, Text, StyleSheet, Document, Page} from '@react-pdf/renderer';

import { SwordDeclarate } from '../../swornDeclarationModal/ViewPDF';




const styles = StyleSheet.create({
  centeredBoldText: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  justifiedText: {
    alignment: 'justify',
    fontSize: 11,
    marginLeft: 10,
  },
  negra:{
    textDecoration: 'underline',
    textDecorationColor: '#000000'
  },
  margin:{
    marginLeft: '30px' 
  },
  tableContainer: {
    width: '88%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    marginBottom: 10,
    marginLeft:'30px',
    marginTop: 10
  },
  row: {
    flexDirection: 'row',
    height: 20,
    borderTopWidth: 1,
    borderTopColor: '#000',
  },
  cell: {
      flex: 1,
      paddingTop: 4,
      borderLeftWidth: 1,
      borderLeftColor: '#000',
      borderRightWidth: 1,
      borderRightColor: '#000',
      textAlign: 'center',
      fontSize: 8,
    },
    bottomRow: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
    },
    borderLeft: {
      borderLeft: 'none',
    },
    borderTop: {
      borderTop: 'none',
    },
    cell1: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '5%',
      height: '100%',
      textAlign: 'center'
    },
    cell2: {
      position: 'absolute',
      left: '5%',
      top: 0,
      width: '50%',
      height: '100%',
      textAlign: 'center'
    },
    cell3: {
      position: 'absolute',
      left: '55%',
      top: 0,
      width: '10%', 
      height: '100%',
      textAlign: 'center'
    },
    cell4: {
      position: 'absolute',
      left: '65%',
      top: 0,
      width: '10%',
      height: '100%',
      textAlign: 'center'
    },
    cell5: {
      position: 'absolute',
      left: '75%',
      top: 0,
      width: '25%', 
      height: '100%',
      textAlign: 'center'
    },
})
const DeclaratePDF = ({date, name, businessAddress, businessName,email, fullName,noCustomsRegistration,numberPackages,phone,products,store, addressArea}: SwordDeclarate) => {
  
  const totalSum = products.reduce((acc, product) => acc + (product.totalValue || 0), 0);

  return (
    <Document>
      <Page>
        
        <View style={styles.centeredBoldText}>
          <Text>
            ANEXO ÚNICO
          </Text>
        </View>
     
        <View style={styles.centeredBoldText}>
          <Text> Declación Jurada</Text>
        
        </View>

     <View style={[styles.justifiedText, styles.margin]}>    
        <Text>
  Yo <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{fullName}</Text> en mi condición de representante legal de la entidad extranjera establecida
  en Cuba <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{businessName}</Text> con domicilio legal <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{businessAddress}</Text>
  teléfono <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{phone}</Text> correo electrónico <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{email}</Text>
  número de Resolución <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>034</Text> aprobada por el Ministro de Comercio Exterior y la Inversión Extranjera, de <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>2023</Text>, 
  <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>CubaNo</Text>. Licencia 0373 emitida por la Cámara de Comercio de la República de Cuba y No. de Inscripción ante Aduana 
  <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{noCustomsRegistration}</Text>
</Text>
     </View>
    
     <View style={[styles.centeredBoldText, {marginTop: 10}]}>
     <Text>Certifico</Text>
         
      </View>

      <View style={[styles.margin, styles.justifiedText]}>
      <Text>
      <Text style={{textDecorationColor:'#000000'}}>1. </Text> Que la entidad extranjera establecida en Cuba, realiza la importacion de insumos (X), 
      muestras comerciales ( ), y/o promociones ( )</Text>
         
        </View>
        <View style={[styles.margin,styles.justifiedText, {marginTop: 10}]}>
        <Text> <Text style={{textDecorationColor:'#000000', fontSize:12}}>. </Text> Cantidad de bultos: <Text style={{textDecorationColor:'#000000', textDecoration:'underline'}}>{numberPackages} </Text></Text>
        
        </View>


        <View style={styles.tableContainer}>
        <View style={styles.row}>
          <Text style={[styles.cell1, styles.cell]}>No</Text>
          <Text style={[styles.cell2, styles.borderLeft, styles.cell]}>descripción de los productos</Text>
          <Text style={[styles.cell3, styles.borderLeft, styles.cell]}>Cantidad</Text>
          <Text style={[styles.cell4, styles.borderLeft, styles.cell]}>Precio Unitario</Text>
          <Text style={[styles.cell5, styles.borderLeft, styles.cell]}>Valor Total</Text>
        </View>
        
        {products.length > 0 && products.map((product, index) => (
  <View key={index} style={[styles.row, styles.bottomRow]}>
    <Text style={[styles.cell1, styles.cell]}>{index + 1}</Text>
    <Text style={[styles.cell2, styles.borderLeft, styles.cell]}>{product.description ?? ''}</Text>
    <Text style={[styles.cell3, styles.borderLeft, styles.cell]}>{product.quantity ?? ''}</Text>
    <Text style={[styles.cell4, styles.borderLeft, styles.cell]}>{product.price ?? ''}</Text>
    <Text style={[styles.cell5, styles.borderLeft, styles.cell]}>{product.totalValue ?? ''}</Text>
  </View>
))}

        <View style={[styles.row, styles.bottomRow, styles.borderTop]}>
          <Text style={[styles.cell1, styles.cell]}></Text>
          <Text style={[styles.cell2, styles.borderLeft, {textDecorationColor:'#000000'}, styles.cell]}>Factura Total</Text>
          <Text style={[styles.cell3, styles.borderLeft, styles.cell]}></Text>
          <Text style={[styles.cell4, styles.borderLeft, styles.cell]}></Text>
          <Text style={[styles.cell5, styles.borderLeft, {textDecorationColor:'#000000'}, styles.cell]}>{products[0]?.priceCodeCurrency || 'USD'} {totalSum} </Text>
        </View>
      </View>


        <View style={[styles.margin,styles.justifiedText, {marginTop: 10}]}>
        <Text>.   Lugar donde se va a consumir, usar o conservar los productos. En el caso de las importaciones
        de las promociones se consiga además fecha y hora del evento</Text>
         
        </View>

        <View style={[styles.margin,styles.justifiedText, {marginTop: 10}]}>
        <Text>Insumos sin valor comercial: <Text>{name}</Text>
         estarán almacenadas en el Almacén situado en <Text>{addressArea}</Text> y tendrán
         salida según las necesidades </Text>
         
        </View>

        <View style={[styles.justifiedText, styles.margin, {marginTop: 10}]}>
        <Text>Fecha: <Text style={{textDecorationColor:'#000000', textDecoration: 'underline'}}>{date}</Text></Text>
          
        </View>

        <View>        
          <View style={[styles.justifiedText, styles.margin, {marginTop: 10}]}>
          <Text>Firma <Text style={{textDecorationColor:'#000000'}}>_____________</Text>                                                   Cuño</Text>
            
          </View>
        </View>

        <View style={[styles.justifiedText, styles.margin, {marginTop: 10}]}>
        <Text>PARA USO DE LA AUDUANA</Text>
        </View>

        
        <View style={[styles.justifiedText, styles.margin, {marginTop: 10}]}>
          <Text>ADUANA________________________No. SOLICITUD___________________________</Text>
          </View>
          
        

        <View>
        <View style={[styles.justifiedText, styles.margin]}>
          <Text>AUTORIZADO ( )                                   DENEGADO ( )</Text>
          </View>
        </View>

        <View style={[styles.justifiedText, styles.margin, {marginTop: 10}]}>
        <Text>Nombre Apellidos y cargo, firma, cuño o sello personal </Text>
           
        </View>

        <View style={[styles.justifiedText, styles.margin, {marginTop: 10}]}>
        <Text>
          Fecha: 
          </Text> 
        </View>
      </Page>
    </Document>
  );
};

export default DeclaratePDF;