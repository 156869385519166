import { View, Text, StyleSheet } from "@react-pdf/renderer";
import TableinContainer from "./TableinContainer";
import TableInPacking from "./TabbleInPacking";

interface dataPacking{
  quantityOfContainers: number, 
  totalCubic: number,
  grossWeight: number,
  netWeight: number,
  totalCost:number
  products:[{
    details:[{
      tariffCode: string;
        description: string;
        internalCode: string;
        quantity: number;
    }]
    name: string
  }]
}

const TablepackingList = ({products,totalCost, quantityOfContainers, totalCubic, grossWeight, netWeight}: dataPacking) => {
    const styles = StyleSheet.create({
        tableContainer: {
          width: '88%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
          marginLeft:'35px',
          marginTop: '10px'
        },
        row: {
          flexDirection: 'row',
          padding:0,
          height: 25,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        row2: {
          flexDirection: 'row',
    
          height: 15,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
            height: '100%'
          },
          cellXXX: {
            flex: 1,
            paddingTop: 2,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
            height: '100%'
          },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }, 
          border: {
            border: 'none'
          },cell1: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell2: {
            position: 'absolute',
            left: '10%',
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell3: {
            position: 'absolute',
            left: '20%',
            top: 0,
            width: '30%', 
            height: '100%',
            textAlign: 'center'
          },
          cell4: {
            position: 'absolute',
            left: '50%',
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell5: {
            position: 'absolute',
            left: '60%',
            top: 0,
            width: '40%', 
            height: '100%',
            textAlign: 'center'
          },
          cell6: {
            position: 'absolute',
            left: '60%',
            top: 0,
            width: '7%', 
            height: '100%',
            textAlign: 'center'
          },
          cell7: {
            position: 'absolute',
            left: '67%',
            top: 0,
            width: '9%', 
            height: '100%',
            textAlign: 'center'
          },
          cell8: {
            position: 'absolute',
            left: '76%',
            top: 0,
            width: '9%', 
            height: '100%',
            textAlign: 'center'
          },
          cell9: {
            position: 'absolute',
            left: '85%',
            top: 0,
            width: '15%', 
            height: '100%',
            textAlign: 'center'
          },
          cell10: {
            position: 'absolute',
            left: '0%',
            top: 0,
            width: '60%', 
            height: '100%',
            textAlign: 'center'
          },
          parteArriba: {
            width: '100%',
            flexDirection: 'column',
            height: '25%',
            fontSize: '25%',
            padding:0 
          },
          parteAbajo:{
            width: '100%',
            flexDirection:'row',
            height: '75%',
            fontSize: 6, 
            padding: 0
          }
    });
  
    return (
      <View style={styles.tableContainer}>
        <View style={styles.row}>
            <View style={[styles.cell, styles.cell1]}>
              <Text style={[styles.cell,styles.border, ]}>Item</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell2]}>
              <Text style={[styles.cell, styles.border, ]}>Codigo</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell3]}>
              <Text style={[styles.cell, styles.border, ]}>Descripción de la marca</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell4]}>
              <Text style={[styles.cell, styles.border, ]}>Referecia</Text>
            </View>


            <View style={[styles.row, styles.bottomRow, styles.cell5, {padding: 0}, styles.border]}>
            <TableinContainer/>
            </View>

            </View>


        <View style={[styles.row, {height:15}]}>
          <Text style={styles.cell}>2</Text>
        </View>
        <View style={[styles.row, {height:15,}]}>
          <Text style={styles.cell}>2</Text>
        </View>


         <View style={[styles.row]}>
         <View style={[styles.cell, styles.cell10,{padding:0}]}>
              <TableInPacking/>
            </View>
        <View style={[styles.cell, styles.cell6, styles.borderLeft]}>
              <Text style={[styles.cell,styles.border, ]}>1</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell7]}>
              <Text style={[styles.cell, styles.border, ]}>2342342</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell8]}>
              <Text style={[styles.cell, styles.border, ]}>asdasdasd2342</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell9]}>
              <Text style={[styles.cell, styles.border,  ]}>234234</Text>
            </View>   
        </View> 


        <View style={[styles.row2, styles.bottomRow, ]}>
            <View style={[styles.cellXXX, styles.cell10, {height:15}]}>
              <Text style={[styles.cellXXX, styles.border, {fontSize: 8, height:15}  ]}>Subtotal</Text>
            </View>
            <View style={[styles.cellXXX, styles.cell6, styles.borderLeft, {height:15}]}>
              <Text style={[styles.cellXXX,styles.border, {fontSize: 8, height:15}]}>1</Text>
            </View>
            <View style={[styles.cellXXX, styles.borderLeft, styles.cell7, {height:15}]}>
              <Text style={[styles.cellXXX, styles.border, {fontSize: 8, height:15}]}>2342342</Text>
            </View>
            <View style={[styles.cellXXX, styles.borderLeft, styles.cell8, {height:15}]}>
              <Text style={[styles.cellXXX, styles.border, {fontSize: 8, height:15}]}>asdasdasd2342</Text>
            </View>
            <View style={[styles.cellXXX, styles.borderLeft, styles.cell9, {height:15}]}>
              <Text style={[styles.cellXXX, styles.border, {fontSize: 8, height:15}]}>234234</Text>
            </View> 
        </View>





      </View>
    );
  };

export default TablepackingList