import { useState } from "react";
import query from "./APIServices";
import useServer from "./useServerMain";

import { generateUrlParams } from "../utils/helpers";
import { setOriginDestinys } from "../store/slices/nomenclatorSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  AmountToDistributeInterface,
  PaginateInterface,
  PreviewAmountInterface,
} from "../interfaces/ServerInterfaces";
import { toast } from "react-toastify";
import { CertificateOriginData } from "../reports/CertificateofOrigin";

const useServerLoad = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginate, setPaginate] = useState<PaginateInterface | null>(null);

  const [amountToDistribute, setAmountToDistribute] =
    useState<AmountToDistributeInterface | null>(null);

  const [previewAmount, setPreviewAmount] =
    useState<PreviewAmountInterface[] | null>(null);

  const dispatch = useAppDispatch();

  const { originDestinys } = useAppSelector((state) => state.nomenclator);

  const { manageErrors } = useServer();

  const findAllOriginDestiny = async (
    filter?: Record<string, string | number | boolean | null>
  ) => {
    setIsFetching(true);

    await query
      .get(`/administration/origindestiny${generateUrlParams(filter)}`)
      .then((resp) => {
        dispatch(setOriginDestinys(resp.data.items));
        setPaginate({
          totalItems: resp.data.totalItems,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
        });
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const newOriginDestiny = async (
    data: {
      name: string;
      countryId: number;
      observations: string;
    },
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/administration/origindestiny`, data)
      .then((resp) => {
        dispatch(setOriginDestinys([resp.data, ...originDestinys]));

        toast.success("Creado correctamente");

        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const editOriginDestiny = async (
    data: {
      name: string;
      countryId: number;
      observations: string;
    },
    callback: Function,
    originDestinyId: number
  ) => {
    setIsFetching(true);

    await query
      .patch(`/administration/origindestiny/${originDestinyId}`, data)
      .then((resp) => {
        dispatch(
          setOriginDestinys(
            originDestinys.map((item) => {
              if (item.id === originDestinyId) {
                return resp.data;
              } else {
                return item;
              }
            })
          )
        );

        toast.success("Editado correctamente");

        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };


  const certificateOrigenM = async (
  
      data: {
        multiBuyedReceipt: string[]
        bill: number;
        date: Date;
      },
      callback: Function
  ) => {
    setIsFetching(true);
  
    try {
      const resp =  
      await query
        .post(`/administration/certificateoforigin?documents=single`, data)
        
        return resp.data.data_to_send
        
    } catch (error) {
      manageErrors(error)
    } finally {
      setIsFetching(false);
    }
  };

  

  const swornDeclarationM = async (
  
    data: {
      fullName: string, 
      noCustomsRegistration: number, 
      email: string, 
      phone: number, 
      store: number, 
      numberPackages: number, 
      multiBuyedReceipt: string[],
      date: Date,
      addressArea: string
    },
    callback: Function
) => {
  setIsFetching(true);

  try {
    const resp =  
    await query
      .post(`/administration/sworndeclaration`, data)
      return resp.data.data_to_send
        
    } catch (error) {
      manageErrors(error)
  } finally {
    setIsFetching(false);
  }
};


const commercialInvoiceM = async (
  
  data: {
    bill: number, 
    shippedBy: string, 
    flete: number, 
    sure: number,
    multiBuyedReceipt: string[],
    date: Date,
    documents: string
  },
  callback: Function
) => {
setIsFetching(true);

try {
  const resp =  
  await query
    .post(`/administration/commercialinvoice?documents=${data.documents}`, data)
    return resp.data.data_to_send
        
    } catch (error) {
      manageErrors(error)
} finally {
  setIsFetching(false);
}
};


const packingListM = async (
  
  data: {
    bill: number, 
    shippedBy: string, 
    quantityOfContainers: number, 
    totalCubic: number,
    multiBuyedReceipt: string[],
    documents: string, 
    date: Date
  },
  callback: Function
) => {
setIsFetching(true);

try { 
  const resp =  
  await query
    .post(`/administration/packinglist?documents=${data.documents}`, data)
    return resp.data.data_to_send
        
    } catch (error) {
      manageErrors(error)
} finally {
  setIsFetching(false);
}
};

const applicationModel = async (
  
  data: { 
    date: Date, 
    multiBuyedReceipt: string[]
    exhibit: string, 
    contract: string, 
    supplement:string, 
    bill: number, 
    rad: string, 
    abf: string, 
    gabl: string,
    email: string, 
    phone: number, 
    fullName: string, 
    entityName: string, 
    entityAddress: string, 
    entityCode: string, 
    passport: string, 
    position: string},
  callback: Function
) => {
setIsFetching(true);

try {
  const resp =  
  await query
    .post(`/administration/applicationmodel`, data)
    return resp.data.data_to_send
        
  } catch (error) {
    manageErrors(error)
} finally {
  setIsFetching(false);
}
};


  const deleteOriginDestiny = async (
    originDestinyId: number,
    callback: Function
  ) => {
    setIsFetching(true);
    console.log(originDestinyId, 'Hola')
    try {
      
      await query
        .deleteAPI(`/administration/origindestiny/${originDestinyId}`, {})
        .then((resp) => {
          dispatch(
            setOriginDestinys(
              originDestinys.filter((item) => item.id !== originDestinyId)
            )
          );
          toast.success("Origen/Destino eliminado correctamente");
          callback();
        });
    } catch (e) {
      manageErrors(e);
    }
  
    setIsFetching(false);
  };
  const getAmountToDistribute = async (
    data: {
      listAccountIds: Array<number>;
      listAccountTagIds: Array<number>;
      cargoStatus: string;
      dateFrom: string;
      dateTo: string;
    },
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/administration/buyedreceipt/distribute/accounts`, data)
      .then((resp) => {
        setAmountToDistribute(resp.data);

        toast.success("Información lista");

        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const processAmountToDistribute = async (
    data: {
      listCargosIds: Array<number>;
      totalAmountToTransfer: number;
      type: string;
    },
    callback: Function
  ) => {
    setIsFetching(true);

    await query
      .post(`/administration/buyedreceipt/distribute/process`, data)
      .then(() => {
        toast.success("Operación realizada con éxito.");

        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const previewAmountToDistribute = async (
    data: {
      listCargosIds: Array<number>;
      totalAmountToTransfer: number;
      type: string;
    },
    callback: Function
  ) => {
    setIsLoading(true);

    await query
      .post(`/administration/buyedreceipt/distribute/preview`, data)
      .then((resp) => {
        setPreviewAmount(resp.data);

        toast.success("Operación realizada con éxito.");

        callback();
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsLoading(false);
  };

  return {
    isLoading,
    isFetching,
    paginate,
    findAllOriginDestiny,
    newOriginDestiny,
    editOriginDestiny,
    amountToDistribute,
    getAmountToDistribute,
    processAmountToDistribute,
    previewAmountToDistribute,
    previewAmount,
    deleteOriginDestiny,
    certificateOrigenM,
    commercialInvoiceM,
    applicationModel,
    packingListM,
    swornDeclarationM
  };
};

export default useServerLoad;
