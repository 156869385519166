import { View, Text, StyleSheet } from "@react-pdf/renderer";


interface ProductDetail {
  description: string;
  internalCode: string;
  quantity: number;
  tariffCode: string;
}

interface Product {
  item: ProductDetail[];
  name: string;
}

interface Products {
  products: Product[];
}

const TableCerti = ({products}: Products) => {
    const styles = StyleSheet.create({
        tableContainer: {
          width: '88%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
          marginLeft:'35px',
          marginTop: '10px'
        },
        row: {
          flexDirection: 'row',
          height: 20,
          borderTopWidth: 1,
          borderTopColor: '#000',
          borderBottom: 1,
          borderBottomColor: '#000'
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
          },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }, 
          border: {
            border: 'none'
          },
          cell1: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell2: {
            position: 'absolute',
            left: '20%',
            top: 0,
            width: '40%',
            height: '100%',
            textAlign: 'center'
          },
          cell3: {
            position: 'absolute',
            left: '60%',
            top: 0,
            width: '15%',
            height: '100%',
            textAlign: 'center'
          },
          cell4: {
            position: 'absolute',
            left: '80%',
            top: 0,
            width: '20%',
            height: '100%',
            textAlign: 'center'
          },
          cell5: {
            position: 'absolute',
            left: '10%',
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell6: {
            position: 'absolute',
            left: '75%',
            top: 0,
            width: '5%',
            height: '100%',
            textAlign: 'left'
          },
          cell7: {
            position: 'absolute',
            left: '80%',
            top: 0,
            width: '20%',
            height: '100%',
            textAlign: 'center'
          },
          
    });
  
    return (
      <View style={styles.tableContainer}>
        <View style={[styles.row, styles.bottomRow]}>
            <View style={[styles.cell, styles.cell1]}>
              <Text style={[styles.cell,styles.border, ]}>Item</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell5]}>
              <Text style={[styles.cell, styles.border, ]}>Codigo</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell2]}>
              <Text style={[styles.cell, styles.border, ]}>Descripción de la marca</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell3]}>
              <Text style={[styles.cell, styles.border, ]}>Referecia</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell4]}>
              <Text style={[styles.cell, styles.border,]}>Cantidad</Text>
            </View>  
        </View>
        
         {products.map((product, index) => (
  <>
    <View key={`${index}`} style={[styles.row, {borderTop: 'none'}]}>
      <Text style={styles.cell}> {product.name}</Text>
    </View>
    {product.item.map((detail, detailIndex) => (
      <View key={`${product.name}-${detailIndex}`} style={[styles.row, {borderTop: 'none'}]}>
        <View style={[styles.cell, styles.cell1]}>
          <Text style={[styles.cell, styles.border, ]}>{detailIndex + 1}</Text>
        </View>
        <View style={[styles.cell, styles.borderLeft, styles.cell5]}>
          <Text style={[styles.cell, styles.border, ]}>{detail.tariffCode}</Text>
        </View>
        <View style={[styles.cell, styles.borderLeft, styles.cell2]}>
          <Text style={[styles.cell, styles.border, ]}>{detail.description}</Text>
        </View>
        <View style={[styles.cell, styles.borderLeft, styles.cell3]}>
          <Text style={[styles.cell, styles.border, ]}>{detail.internalCode}</Text>
        </View>
        <View style={[styles.cell, styles.borderLeft, styles.cell6]}>
          <Text style={[styles.cell, styles.border,]}>U</Text>
        </View>
        <View style={[styles.cell, styles.borderLeft, styles.cell7]}>
          <Text style={[styles.cell, styles.border,]}>{detail.quantity}</Text>
        </View>
      </View>
    ))}
  </>
))} 


        </View>
      
    );
  };

export default TableCerti;



// {products.map(()=>{<View style={[styles.row]}>
//           <Text style={styles.cell}>{name}</Text>
//         </Vi>ew>
//         {details.map((item, index) => (
//           <View key={index} style={[index === details.length - 1 ? styles.bottomRow : styles.row, styles.row]}>
//               <View style={[styles.cell, styles.cell1]}>
//                 <Text style={[styles.cell, styles.border, ]}>{index + 1}</Text>
//               </View>
//               <View style={[styles.cell, styles.borderLeft, styles.cell5]}>
//                 <Text style={[styles.cell, styles.border, ]}>{item.tariffCode}</Text>
//               </View>
//               <View style={[styles.cell, styles.borderLeft, styles.cell2]}>
//                 <Text style={[styles.cell, styles.border, ]}>{item.description}</Text>
//               </View>
//               <View style={[styles.cell, styles.borderLeft, styles.cell3]}>
//                 <Text style={[styles.cell, styles.border, ]}>{item.internalCode}</Text>
//               </View>
//               <View style={[styles.cell, styles.borderLeft, styles.cell6]}>
//                 <Text style={[styles.cell, styles.border,]}>U</Text>
//               </View>
//               <View style={[styles.cell, styles.borderLeft, styles.cell7]}>
//                 <Text style={[styles.cell, styles.border,]}>{item.quantity}</Text>
//               </View
//             </View>