import { View, Text, StyleSheet } from "@react-pdf/renderer";


const TableApplicationodelIn1 = () => {
    const styles = StyleSheet.create({
        tableContainer: {
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
        },
        row: {
          flexDirection: 'row',
          height: 15,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
          },
          cell1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '80%',
          },
          cell2: {
            position: 'absolute',
            left: '80%',
            top: 0,
            width: '20%',
            height: '100%',
            textAlign: 'center',
            borderRightWidth: 1,
          borderRightColor: '#000',
        },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }
    });
  
    return (
      <View style={[styles.tableContainer,]}>
        <View style={styles.row}>
          <Text style={[styles.cell, styles.cell1]}>{'a) Permiso de Autorización Importación para Comercialización'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>{'b) Permiso de Autorización Importación para Muestra Expositiva'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>{'c) Permiso de Autorización Importación para Pruebas de Campo'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>{'d) Permiso de Autorización Importación para Uso no Comercial'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
        <View style={[styles.row, styles.bottomRow]}>
          <Text style={[styles.cell, styles.cell1]}>{'e) Permiso de Autorización Importación para Donaciones'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
        <View style={[styles.row, styles.bottomRow]}>
          <Text style={[styles.cell, styles.cell1]}>{'f) Permiso de Autorización Importación para Retención y Notificación Aduanal'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
        <View style={[styles.row, styles.bottomRow]}>
          <Text style={[styles.cell, styles.cell1]}>{'g) Permiso de Homologación de equipos'}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>{}</Text>
        </View>
      </View>
    );
  };

export default TableApplicationodelIn1;