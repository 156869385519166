import { useContext } from "react";
import { useAppSelector } from "../../store/hooks";
import { ProductContext } from "./wizardContainer";
import { SelectInterface } from "../../interfaces/InterfacesLocal";
import Input from "../../components/forms/Input";
import Select from "../../components/forms/Select";
import CurrencyInput from "../../components/forms/CurrencyInput";
import InputDuration from "../../components/forms/InputDuration";
import Toggle from "../../components/forms/Toggle";
import TextArea from "../../components/forms/TextArea";
import Button from "../../components/misc/Button";


const AplicationForm1 = () => {
  
  const { control, stepDown, watch } = useContext(ProductContext);

  return (
    <>
      <div className="h-96 border border-slate-300 rounded p-2 overflow-y-visible">


        <div className="grid grid-cols-2 gap-3">
          <Input
            name="contract"
            label="Contrato"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />

          <Input
            name="exhibit"
            label="Anexo"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="supplement"
            name="Suplemento"
            type="text"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />

        <Input
            label="Factura"
            name="bill"
            type="text"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="ABF"
            name="abf"
            type="text"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />

        <Input
            label="G.A/BL"
            name="gabl"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
        
          <Input
            label="RAD"
            name="rad"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Siguiente" full />
      </div>
    </>
  );
};

export default AplicationForm1;
