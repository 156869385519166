import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { ProductContext } from "./wizardContainer";
import { SelectInterface } from "../../interfaces/InterfacesLocal";
import Input from "../../components/forms/Input";
import Select from "../../components/forms/Select";
import CurrencyInput from "../../components/forms/CurrencyInput";
import InputDuration from "../../components/forms/InputDuration";
import Toggle from "../../components/forms/Toggle";
import TextArea from "../../components/forms/TextArea";
import Button from "../../components/misc/Button";
import useServerArea from "../../api/useServerArea";
import { CargoData } from "../CertificateofOrigin";
import MultiSelect from "../../components/forms/Multiselect";
import DateInput from "../../components/forms/DateInput";
import Check from "../../components/forms/GenericCheck";


const AplicationForm3 = () => {
  

  const { control, stepDown, watch } = useContext(ProductContext);
   

      const [xData, setXData] = useState({
        a: false,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false
      });

      const handleCheckChange = (value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setXData(prev => ({
          ...prev,
          [value]: isChecked
        }));
      };

  return (
    <>
  <div className="h-96 w-full max-w-[800px] border border-slate-300 rounded p-2 overflow-y-visible">
    
  <div>
  <Check
          value="a"
          label="Permiso de Autorización Importación para Comercialización"
          onChange={(checked) => handleCheckChange('a', checked)}
        />
      </div>
      <div>
        <Check
          value="b"
          label="Permiso de Autorización Importación para Muestra Expositiva"
          onChange={(checked) => handleCheckChange('b', checked)}
        />
      </div>
      <div>
        <Check
          value="c"
          label="Permiso de Autorización Importación para Pruebas de Campo"
          onChange={(checked) => handleCheckChange('c', checked)}
        />
        <Check
          value="d"
          label="Permiso de Autorización Importación para Uso no Comercial"
          onChange={(checked) => handleCheckChange('d', checked)}
        />
      </div>
      <div>
        <Check
          value="e"
          label="Permiso de Autorización Importación para Donaciones"
          onChange={(checked) => handleCheckChange('e', checked)}
        />
      </div>
      <div>
        <Check
          value="f"
          label="Permiso de Autorización Importación para Retención y Notificación Aduanal"
          onChange={(checked) => handleCheckChange('f', checked)}
        />
        <Check
          value="g"
          label="Permiso de Homologación de equipos"
          onChange={(checked) => handleCheckChange('g', checked)}
        />
  </div>
      </div>
      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Siguiente" full />
      </div>
    </>
  );
};

export default AplicationForm3;