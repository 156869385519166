import { useContext } from "react";
import { ProductContext } from "./wizardContainerDeclaration";
import Input from "../../components/forms/Input";
import Button from "../../components/misc/Button";


const DeclarationForm = () => {
  
  const { control, stepDown, watch } = useContext(ProductContext);
  return (
    <>
      <div className="h-96 border border-slate-300 rounded p-2 overflow-y-visible">


        <div className="grid grid-cols-2 gap-3">
          <Input
            name="fullName"
            label="Nombre y apellidos"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />

          <Input
            name="noCustomsRegistration"
            label="No. Inscripción Aduana"
            control={control}
            type="number"
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="Correo electónico"
            name="email"
            type="text"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />

          <Input
            label="Teléfono"
            name="phone"
            textAsNumber
            type="number"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Siguiente" full />
      </div>
    </>
  );
};

export default DeclarationForm;
