import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: 25,
        borderRightWidth: 1,
        borderRightColor: 'black',
        borderBottomWidth: 0,
        borderTopWidth: 0,
    },
    innerContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    topContainer: {
        height: '40%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 7,
    },
    bottomContainer: {
        flexDirection: 'row',
        height: '60%',
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 7,
        borderTopWidth: 1,
        borderTopColor: 'black',
        
        
    },
    itemContainer: {
        width: '15%',
        textAlign: 'center',
        height: '100%',
        borderRightWidth: 1,
        borderRightColor: 'black',
    },
    itemContainer2: {
        width: '20%',
        textAlign: 'center',
        height: '100%',
        borderRightWidth: 1,
        borderRightColor: 'black',
    },
    itemContainer3: {
        width: '20%',
        textAlign: 'center',
        height: '100%',
        borderRightWidth: 1,
        borderRightColor: 'black',
    },
});

const TableinContainer = () => {
    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                <View style={styles.topContainer}>
                    <Text>Cantidad</Text>
                </View>
                <View style={[styles.bottomContainer, {padding:0}]}>
                    <View style={[styles.itemContainer, {padding:0}]}>
                        <Text>PCS</Text>
                    </View>
                    <View style={[styles.itemContainer2, {padding:0}]}>
                        <Text>N.W / KGS</Text>
                    </View>
                    <View style={[styles.itemContainer3, {padding:0}]}>
                        <Text>H.W / KGS</Text>
                    </View>
                    <View style={[ {padding:0}]}>
                        <Text>Camtidad de Cartones</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default TableinContainer;