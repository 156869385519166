import React, { useContext } from "react";
import Input from "../../../components/forms/Input";
import Select from "../../../components/forms/Select";
import { ProductContext } from "./NewWizardContainer";
import { useAppSelector } from "../../../store/hooks";
import Button from "../../../components/misc/Button";
import Multiselect from "../../../components/forms/Multiselect";
import { SelectInterface } from "../../../interfaces/InterfacesLocal";
import CurrencyInput from "../../../components/forms/CurrencyInput";
import TextArea from "../../../components/forms/TextArea";
import Toggle from "../../../components/forms/Toggle";
import InputColor from "../../../components/forms/InputColor";
import InputDuration from "../../../components/forms/InputDuration";

const ProductForm = () => {
  const { business } = useAppSelector((state) => state.init);
  const { productCategories, measures, salesCategories, areas } =
    useAppSelector((state) => state.nomenclator);
  const { control, stepDown, watch } = useContext(ProductContext);

  const productType = control?._getWatch("type") ?? "";

  const module_booking =
    business?.configurationsKey.find((itm) => itm.key === "module_booking")
      ?.value === "true";

  //Data for Select and Multiselect Components ---------------------------------------------------------------------
  const selectSalesCategory: SelectInterface[] = [];
  salesCategories.map((cat) =>
    selectSalesCategory.push({
      name: cat.name,
      id: cat.id,
    })
  );

  const selectStockCategory: SelectInterface[] = [];
  productCategories?.forEach((cat) =>
    selectStockCategory.push({
      name: cat.name,
      id: cat.id,
    })
  );

  const selectMeasure: SelectInterface[] = [];
  measures.forEach((item) =>
    selectMeasure.push({
      id: item.code,
      name: item.value,
    })
  );

  const productionAreas: SelectInterface[] = [];
  areas?.forEach(
    (item) =>
      item.type === "MANUFACTURER" &&
      productionAreas.push({
        name: item.name,
        id: item.id,
      })
  );

  //----------------------------------------------------------------------------------------------------
  const price = control?._getWatch("prices");
  return (
    <>
      <div className="h-auto border border-slate-300 rounded p-6 overflow-y-visible">
        <div>
          <Input
            label="Nombre"
            name="name"
            control={control}
            placeholder="Inserte el nombre del producto"
            rules={{ required: "Este campo es requerido" }}
          />
        </div>
        <div className={`grid grid-cols-2 gap-3`}>
          
          {/* {["STOCK", "MENU", "SERVICE", "ADDON", "COMBO", "VARIATION"].includes(
            productType
          ) && ( */}
            <Select
              label="Categoría de Venta"
              data={selectSalesCategory}
              name="salesCategoryId"
              control={control}
            />
          {/* )} */}

          {/* {["STOCK", "RAW", "MANUFACTURED", "WASTE", "ASSET"].includes(
            productType
          ) && ( */}
            <Select
              label="Categoría de almacén"
              data={selectStockCategory}
              name="productCategoryId"
              control={control}
            />
          {/* )} */}
        </div>

        <div className="grid grid-cols-2 gap-3">
          {/* {["STOCK", "MENU", "SERVICE", "ADDON", "COMBO", "VARIATION", "ASSET", "INPUT"].includes(
            productType
          ) && ( */}
            <>
              <CurrencyInput
                label="Precio de venta"
                currencies={
                  business?.availableCurrencies.map(
                    (currency) => currency.code
                  ) ?? []
                }
                name="prices"
                control={control}
                placeholder="$0.00"
                byDefault={
                  !price
                    ? { price: 0, codeCurrency: business?.costCurrency! }
                    : price
                }
                defaultCurrency={business?.costCurrency!}
              />
              <Input
                label="Potencia (W)"
                name="pow"
                type="number"
                control={control}
                placeholder="Potencia"
              />
            </>
          {/* )} */}
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="Modelo"
            name="model"
            type="text"
            control={control}
            placeholder="Modelo"
          />

          <Input
            label="Nombre de la marca"
            name="brand"
            type="text"
            control={control}
            placeholder="Nombre de la marca"
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            name="grossWeight"
            label="Peso bruto (Kg)"
            control={control}
            textAsNumber
            type="number"
          />

          <Input
            name="netWeight"
            label="Peso neto (Kg)"
            control={control}
            textAsNumber
            type="number"
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="Código arancelario"
            name="tariffCode"
            type="text"
            control={control}
            placeholder="Código arancelario"
          />

          <Input
            label="Código interno"
            name="internalCode"
            type="text"
            control={control}
            placeholder="Código interno"
          />
        </div>

        {["SERVICE"].includes(productType) && module_booking && (
          <div className="col-span-2 grid grid-cols-2 items-center">
            <div>
              <Toggle
                control={control}
                name="hasDuration"
                title="Definir duración"
              />
            </div>
            <div className="mb-2">
              {watch("hasDuration") && (
                <InputDuration
                  name="duration"
                  control={control}
                  label="Duración"
                />
              )}
            </div>
          </div>
        )}

        <div className="col-span-2">
          <TextArea
            control={control}
            name="description"
            label="Descripción"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Siguiente" full />
      </div>
    </>
  );
};

export default ProductForm;
