import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { ProductContext } from "./wizardContainer";
import { SelectInterface } from "../../interfaces/InterfacesLocal";
import Input from "../../components/forms/Input";
import Select from "../../components/forms/Select";
import CurrencyInput from "../../components/forms/CurrencyInput";
import InputDuration from "../../components/forms/InputDuration";
import Toggle from "../../components/forms/Toggle";
import TextArea from "../../components/forms/TextArea";
import Button from "../../components/misc/Button";
import useServerArea from "../../api/useServerArea";
import { CargoData } from "../CertificateofOrigin";
import MultiSelect from "../../components/forms/Multiselect";
import DateInput from "../../components/forms/DateInput";
import Check from "../../components/forms/GenericCheck";


const AplicationForm4 = () => {
  
//   const [dataResponse, setDataResponse] = useState<originData>()
  const {fetchCargos} = useServerArea()
  const [cargosData, setCargosData] = useState<CargoData[]>([]);
  const { control, stepDown, watch } = useContext(ProductContext);
   useEffect(() => {
        fetchCargos()
          .then(items => {
            // Manejar el éxito, por ejemplo:
            setCargosData(items);
          })
          .catch(error => {
            // Manejar el error
            console.error('Error al cargar cargos:', error);
          });
      }, []);

  return (
    <>
      <div className="h-96 border border-slate-300 rounded p-2 overflow-y-visible">

    
        <div className="grid grid-cols-2 gap-3">
          
            <MultiSelect
                name="multiBuyedReceipt"
                data={cargosData}
                label="Cargas/Contenedores (*)"
                control={control}
                rules={{ required: "Campo requerido" }}
            /> 

      </div>

      
      </div>
      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Siguiente" full />
      </div>
    </>
  );
};

export default AplicationForm4;