/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import Button from '../components/misc/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import AsyncComboBox from '../components/forms/AsyncCombobox';
import Input from '../components/forms/Input';
import useServerArea from '../api/useServerArea';
import { CargoData } from './CertificateofOrigin';
import MultiSelect from '../components/forms/Multiselect';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import useServerLoad from '../api/useServerLoad';
import PackingListNoInvoicePDF, { originData3 } from './components/pdf/PackingListNoInvoicePDF';




const CommercialNoPackingListReport = () => {

    const { handleSubmit, control } = useForm();
    const {packingListM} = useServerLoad()
    const [dataResponse, setDataResponse] = useState<originData3>()
    const {fetchCargos} = useServerArea()
    const [cargosData, setCargosData] = useState<CargoData[]>([]);
  
    useEffect(() => {
      fetchCargos()
        .then(items => {
          // Manejar el éxito, por ejemplo:
          setCargosData(items);
        })
        .catch(error => {
          // Manejar el error
          console.error('Error al cargar cargos:', error);
        });
    }, []);

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            const responseData = await packingListM({
                multiBuyedReceipt: data.multiBuyedReceipt,
                bill: data.bill,
                date: data.date,
                shippedBy: data.shippedBy, 
                quantityOfContainers: data.quantityOfContainers, 
                totalCubic: data.totalCubic,
                documents: 'no-commercial',
              }, () => {});
              

            setDataResponse(responseData)
            console.log(data)
            console.log(responseData,'algo');
          } catch (error) {
            console.error("Error:", error);
          }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Complete la información del Lista de Empaque no Comercial
                </h2>
                <div className="flex flex-col gap-2 w-full">

                    <MultiSelect
                                        name="multiBuyedReceipt"
                                        data={cargosData}
                                        label="Cargas/Contenedores (*)"
                                        control={control}
                                        rules={{ required: "Campo requerido" }}
                                        /> 
                     
                    <div className="flex space-x-4">
                    <div className="flex-col w-full">
                        <Input
                            label="No. Factura (Consecutivo)"
                            name="bill"
                            type="number"
                            control={control}
                            rules={{ required: "Este campo es requerido",
                             }}
                        />
                    </div>
                    <div className="flex-col w-full">
                        <Input
                            label="Embarcado por"
                            name="shippedBy"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    </div>
                    <div className="flex space-x-4">
                    <div className="flex-col w-full">
                        <Input
                            label="Cantidad de contenedores"
                            name="quantityOfContainers"
                            type="number"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    <div className="flex-col w-full ">
                        <Input
                            label="Cubicaje total"
                            name="totalCubic"
                            control={control}
                            type="number"
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    </div>
                    
                    

                    {dataResponse && dataResponse !== null &&<PDFViewer>
                        <PackingListNoInvoicePDF 
            products={dataResponse.products}
            date={dataResponse.date}
            bLNumber={dataResponse.bLNumber}
            bill={dataResponse.bill}
            destiny={dataResponse.destiny}
            quantityOfContainers={dataResponse.quantityOfContainers}
            info={dataResponse.info}
            origin={dataResponse.origin}
            shippedBy={dataResponse.shippedBy}
            totalCubic={dataResponse.totalCost}
            totalCost={dataResponse.totalCost}
            grossWeight={dataResponse.grossWeight}
            netWeight={dataResponse.netWeight}
            />
                    </PDFViewer>} 
                    <div className="w-full flex justify-end gap-3 mt-4">
                    
                <div>
                  <Button
                    color="slate-600"
                    textColor="slate-600"
                    type="submit"
                    name="Enviar datos al Formulario"
                    outline
                    //action={() => {
                    //  setShowModal(false);
                    //}}
                  />
                </div>
               {dataResponse && dataResponse !== null && <PDFDownloadLink
            document={<PackingListNoInvoicePDF bill={dataResponse.bill} 
            products={dataResponse.products}
            date={dataResponse.date}
            bLNumber={dataResponse.bLNumber}
            quantityOfContainers={dataResponse.quantityOfContainers}
            destiny={dataResponse.destiny}
            totalCubic={dataResponse.totalCubic}
            info={dataResponse.info}
            origin={dataResponse.origin}
            shippedBy={dataResponse.shippedBy}
            totalCost={dataResponse.totalCost}
            grossWeight={dataResponse.grossWeight}
            netWeight={dataResponse.netWeight}
            />}
            fileName="Certificado_de_Origen.pdf"
            style={{ backgroundColor: '#364954', color: '#FFFFFF', padding: '5px 10px', borderRadius: '0.375rem' }}
          >
            Descargar PDF
          </PDFDownloadLink>}
              </div></div>
            </form>


            
        </div>
    )
}

export default CommercialNoPackingListReport