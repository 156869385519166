/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import Button from '../components/misc/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import AsyncComboBox from '../components/forms/AsyncCombobox';
import Input from '../components/forms/Input';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PDF from './components/pdf/CertificateofOriginPDF';
import useServerLoad from '../api/useServerLoad';
import ComercialInvoicePDF, { originData2 } from './components/pdf/ComercialInvoicePDF';
import MultiSelect from '../components/forms/Multiselect';
import useServerArea from '../api/useServerArea';
import { CargoData } from './CertificateofOrigin';




const CommercialInvoiceReport = () => {

    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control } = useForm();
    const {commercialInvoiceM} = useServerLoad()
    const [dataResponse, setDataResponse] = useState<originData2>()
    const {fetchCargos} = useServerArea()
    const [cargosData, setCargosData] = useState<CargoData[]>([]);
  
    useEffect(() => {
      fetchCargos()
        .then(items => {
          // Manejar el éxito, por ejemplo:
          setCargosData(items);
        })
        .catch(error => {
          // Manejar el error
          console.error('Error al cargar cargos:', error);
        });
    }, []);

    const onSubmit: SubmitHandler<any> = async (data) => {
        try {
            const responseData = await commercialInvoiceM({
                multiBuyedReceipt: data.multiBuyedReceipt,
                bill: data.bill,
                date: data.date,
                shippedBy: data.shippedBy, 
                flete: data.flete, 
                sure: data.sure,
                documents: 'commercial',
              }, () => {});

            setDataResponse(responseData)
            console.log(data)
            console.log(responseData,'algo');
          } catch (error) {
            console.error("Error:", error);
          }
    };
    

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Complete la información de la Factura Comercial
                </h2>
                <div className="flex flex-col gap-2 w-full">

                <MultiSelect
                    name="multiBuyedReceipt"
                    data={cargosData}
                    label="Cargas/Contenedores (*)"
                    control={control}
                    rules={{ required: "Campo requerido" }}
                    />    
                    
                    <div className="flex space-x-4">
                    <div className="flex-col w-full">
                        <Input
                            label="No. Factura (Consecutivo)"
                            name="bill"
                            type='number'
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    <div className="flex-col w-full">
                        <Input
                            label="Embarcado por"
                            name="shippedBy"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    </div>
                    <div className="flex space-x-4">
                    <div className="flex-col w-full">
                        <Input
                            label="Flete"
                            name="flete"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                            type='number'
                        />
                    </div>
                    <div className="flex-col w-full ">
                        <Input
                            label="Seguro"
                            name="sure"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                            type='number'
                        />
                    </div>
                    </div>
                    
                    

                    {dataResponse && dataResponse !== null &&<PDFViewer>
                        <ComercialInvoicePDF bill={dataResponse.bill} 
            products={dataResponse.products}
            date={dataResponse.date}
            bLNumber={dataResponse.bLNumber}
            contryName={dataResponse.contryName}
            destiny={dataResponse.destiny}
            flete={dataResponse.flete}
            info={dataResponse.info}
            origin={dataResponse.origin}
            shippedBy={dataResponse.shippedBy}
            sure={dataResponse.sure}
            totalCost={dataResponse.totalCost}
            totalImportFOB={dataResponse.totalImportFOB}
            />
                    </PDFViewer>} 
                    <div className="w-full flex justify-end gap-3 mt-4">
                    
                <div>
                  <Button
                    color="slate-600"
                    textColor="slate-600"
                    type="submit"
                    name="Enviar datos al Formulario"
                    outline
                    //action={() => {
                    //  setShowModal(false);
                    //}}
                  />
                </div>
               {dataResponse && dataResponse !== null && <PDFDownloadLink
            document={<ComercialInvoicePDF bill={dataResponse.bill} 
            products={dataResponse.products}
            date={dataResponse.date}
            bLNumber={dataResponse.bLNumber}
            contryName={dataResponse.contryName}
            destiny={dataResponse.destiny}
            flete={dataResponse.flete}
            info={dataResponse.info}
            origin={dataResponse.origin}
            shippedBy={dataResponse.shippedBy}
            sure={dataResponse.sure}
            totalCost={dataResponse.totalCost}
            totalImportFOB={dataResponse.totalImportFOB}
            />}
            fileName="Certificado_de_Origen.pdf"
            style={{ backgroundColor: '#364954', color: '#FFFFFF', padding: '5px 10px', borderRadius: '0.375rem' }}
          >
            Descargar PDF
          </PDFDownloadLink>}
              </div></div>
                
            </form>


            
        </div>
    )
}

export default CommercialInvoiceReport