/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import Button from '../components/misc/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import AsyncComboBox from '../components/forms/AsyncCombobox';
import Input from '../components/forms/Input';
import DateInput from '../components/forms/DateInput';
import MultiSelect from '../components/forms/Multiselect';
import useServerArea from '../api/useServerArea';
import { CargoData } from './CertificateofOrigin';



const CommercialPackingListReport = () => {

    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control } = useForm();
    const {fetchCargos} = useServerArea()
    const [cargosData, setCargosData] = useState<CargoData[]>([]);
  
    useEffect(() => {
      fetchCargos()
        .then(items => {
          // Manejar el éxito, por ejemplo:
          setCargosData(items);
        })
        .catch(error => {
          // Manejar el error
          console.error('Error al cargar cargos:', error);
        });
    }, []);

    const onSubmit: SubmitHandler<Record<string, string | number>> = async (data) => {};
    

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Complete la información del Lista de Empaque Comercial
                </h2>
                <div className="flex flex-col gap-2 w-full">

                <MultiSelect
                    name="multiBuyedReceipt"
                    data={cargosData}
                    label="Cargas/Contenedores (*)"
                    control={control}
                    rules={{ required: "Campo requerido" }}
                    /> 
                    <div className="flex space-x-4">
                    <div className="flex-col w-full">
                        <Input
                            label="No. Factura (Consecutivo)"
                            name="bill"
                            type="number"
                            control={control}
                            rules={{ required: "Este campo es requerido", pattern: /^\d{3}$/ }}
                        />
                    </div>
                    <div className="flex-col w-full">
                        <Input
                            label="Embarcado por"
                            name="shippedBy"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    </div>
                    <div className="flex space-x-4">
                    <div className="flex-col w-full">
                        <Input
                            label="Cantidad de contenedores"
                            name="quantityOfContainers"
                            type="number"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    <div className="flex-col w-full ">
                        <Input
                            label="Cubicaje total"
                            name="totalCubic"
                            type="number"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                        />
                    </div>
                    </div>
                    
                    

                    <div className="w-full flex justify-end gap-3 mt-4">
                        <div>
                            <Button
                                color="slate-600"
                                type="submit"
                                name="Generar documento"
                            // loading={isFetching}
                            // disabled={isFetching}
                            />
                        </div>
                    </div>
                </div>
            </form>


            
        </div>
    )
}

export default CommercialPackingListReport