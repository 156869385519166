import React from 'react';
import { View, Text, StyleSheet, Document, Page, Image } from '@react-pdf/renderer';
import SideBySideTextComponent from './SideBySideTextComponent';
import TwoByTwoTable from './TwoByTwoTable';
import OriginDeclaration from './OriginDeclaration';
import TableCerti from './TableCertifi';
import CargoInformation from './CargoInformation';
import TableComercialInvoice from './TableCommercialInvoice';
import TablepackingList from './TablepackingList';
import TableinContainer from './TableinContainer';
import TableUpPacking from './TabbleUpPacking';

export interface originData3 {
  shippedBy: string;
  date: string;
  totalCost: number;
  bLNumber: string;
  info: string;
  origin: string;
  destiny: string;
  quantityOfContainers: number, 
  totalCubic: number,
  bill: string;
  grossWeight: number,
  netWeight: number
  products:[{
    details:[{
      tariffCode: string;
        description: string;
        internalCode: string;
        quantity: number;
    }]
    name: string
  }]
}



const styles = StyleSheet.create({
  itemImage: {
      width: 12,
      height: 12,
      resizeMode: 'contain',
      marginBottom: 5,
    },
})
const PackingListNoInvoicePDF = ({bill,grossWeight, netWeight, totalCost, date, products, bLNumber, quantityOfContainers, destiny,totalCubic, info,origin, shippedBy}: originData3 ) => {
  
  const rightText = `LISTA DE EMPAQUE NO COMERCIAL`

  

  

  return (
    <Document>
      <Page>
      <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
      {/* Your existing content */}
      
    <View style={{ marginTop: 20 }}>
        <SideBySideTextComponent 
            rightText={rightText} 
        />
    </View>
    <View>
            <TwoByTwoTable 
                topRight={bill}
                bottomRight={date}
        />
    </View>
    
    <View>
    <CargoInformation bLNumber={bLNumber}
    origin={origin}
    destiny={destiny}
    shippedBy={shippedBy}
    info={info} />
    </View> 

      {/* <View>
      <TableinContainer/>
      </View> */}

     <View>
      <TablepackingList grossWeight={grossWeight} netWeight={netWeight} totalCost={totalCost} products={products} quantityOfContainers={quantityOfContainers} totalCubic={totalCubic} />
    </View> 

    <View>
      <TableUpPacking/>
    </View>
    
    
    
    
   
    {/* <View>
    <Image
  src="../../../assets/logo/banner+logo.png" style={styles.itemImage}  break={true}
/>
    </View> */}
    
    </View>
      </Page>
    </Document>
  );
};

export default PackingListNoInvoicePDF;