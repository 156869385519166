import React, { useState, createContext, useContext } from "react";
import { useForm, SubmitHandler, Control } from "react-hook-form";
import StepsComponent from "../../components/misc/StepsComponent";
import Fetching from "../../components/misc/Fetching";
import DeclarationForm from "./Form1";
import DeclarationForm2 from "./Form2";
import ViewPDF, { SwordDeclarate } from "./ViewPDF";
import useServerLoad from "../../api/useServerLoad";



interface WizardInterface {
  action: Function;
  closeModal: Function;
  loading: boolean;
}

interface ContextData {
  control?: Control;
  stepUp?: Function;
  stepDown?: Function;
  watch: Function;
  unregister: Function;
}



const contextData: ContextData = { watch: () => {}, unregister: () => {}};

export const ProductContext = createContext(contextData);

const NewWizardContainerDeclaration = ({
  action,
  closeModal,
  loading,
}: WizardInterface) => {
  const { control, handleSubmit, reset, watch, unregister } =
    useForm<Record<string, string | number>>();
    
  //Step Component Data-------------------------------------------------------------
  const [currentStep, setCurrentStep] = useState(0);
  const stepTitles = ["Datos del representante", "Información de la importación", "Visualizar PDF"];

  const stepUp = () => setCurrentStep(currentStep + 1);
  const stepDown = () => {
    setCurrentStep(currentStep - 1);
  };
  //----------------------------------------------------------------------------------------
  const [dataResponse, setDataResponse] = useState<SwordDeclarate>()
  const {swornDeclarationM} = useServerLoad()
  

  //Form Handle -----------------------------------------------------------------------------
  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      if (currentStep !== 2) {
        const responseData = await swornDeclarationM({
          fullName: data.fullName, 
          noCustomsRegistration: data.noCustomsRegistration, 
          email: data.email, 
          phone: data.phone, 
          store: data.store, 
          numberPackages: data.numberPackages, 
          multiBuyedReceipt: data.multiBuyedReceipt,
          addressArea: data.addressArea,        
          date: data.date
        }, () => {});
        console.log(responseData, 'JJJ')
        setDataResponse(responseData);
        stepUp();
      } else {
        action(data, closeModal);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //-----------------------------------------------------------------------------------------

  return (
    <>
      <StepsComponent current={currentStep} titles={stepTitles} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductContext.Provider
          value={{ control, stepUp, stepDown, watch, unregister}}
        >
          {loading && <Fetching />}
          {currentStep === 0 && <DeclarationForm />}
          {currentStep === 1 && <DeclarationForm2 />}
          {currentStep === 2 && <ViewPDF addressArea={dataResponse!.addressArea} name={dataResponse!.name} businessAddress={dataResponse!.businessAddress} businessName={dataResponse!.businessName}
          date={dataResponse!.date} email={dataResponse!.email} fullName={dataResponse!.fullName}
          noCustomsRegistration={dataResponse!.noCustomsRegistration} numberPackages={dataResponse!.numberPackages}
          phone={dataResponse!.phone} products={dataResponse!.products} store={dataResponse!.store}/>}  
        </ProductContext.Provider>
      </form>
    </>
  );
};

export default NewWizardContainerDeclaration;