import { View, Text, StyleSheet } from "@react-pdf/renderer";

interface TableProps {
  bLNumber:string
  origin: string,
  destiny: string
  shippedBy: string
  info: string
}

const CargoInformation = ({bLNumber, origin, destiny, shippedBy, info}: TableProps) => {
  const styles = StyleSheet.create({
    tableContainer: {
      marginBottom: 10,
      width: '88%',
      marginLeft: '35px' 
    },
    row: {
      flexDirection: 'row',
      height: 20,
      marginTop: '10px'
    },
    row2: {
      flexDirection: 'row',
      height: 30,
      marginTop: '10px'
    },
    cell: {
      flex: 1,
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: 'center',
      fontSize: 8,
      whiteSpace: 'normal'
    },
    bottomRow: {
      borderBottomWidth: 0,
    },
    cell1: {
      width: '10%',
      textAlign: 'right'
    },
    cell2: {
      width: '88%',
      textAlign: 'left',
      marginLeft: '5px'
    },
    cell3: {
      width: '20%',
      textAlign: 'left',
      marginLeft: '5px'
    },
    cell4: {
      width: '20%',
      textAlign: 'right',
      marginLeft: '5px'
    },
    cell5: {
      width: '50%',
      textAlign: 'left',
      marginLeft: '5px'
    },
    cell6: {
      width: '15%',
      textAlign: 'right',
      marginLeft: '5px'
    },
    cell7: {
      width: '25%',
      textAlign: 'center',
      marginLeft: '5px'
    },
    cell8: {
      width: '10%',
      textAlign: 'center'
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      borderBottomStyle: 'solid'
    },
    negrita:{
      fontWeight: 'black'
    }
  });

  return (
    <View style={[styles.tableContainer]}>

      <View style={styles.row}>
        <View style={[styles.row, styles.cell1]}>
          <Text style={[styles.cell, styles.cell1, styles.negrita]}>A:</Text>
        </View>
        <View style={[styles.row, styles.cell2, styles.borderBottom]}>
          <Text style={[styles.cell, styles.cell2]}>{info}</Text>
        </View>
      </View>
      
<View style={styles.row2}>
  <View style={[styles.row2, styles.cell8]}>
    <Text style={[styles.cell, styles.cell8, styles.negrita]}>Embarcado por:</Text>
  </View>
  <View style={[styles.row2, styles.cell3, styles.borderBottom]}>
    <Text style={[styles.cell, styles.cell3, {textAlign: 'center'}]}>{shippedBy}</Text>
  </View>
  <View style={[styles.row2, styles.cell1]}>
    <Text style={[styles.cell, styles.cell1]}>Desde:</Text>
  </View>
  <View style={[styles.row2, styles.cell3, styles.borderBottom]}>
    <Text style={[styles.cell, styles.cell3, {textAlign: 'center'}]}>{origin}</Text>
  </View>
  <View style={[styles.row2, styles.cell4]}>
    <Text style={[styles.cell, styles.cell4]}>A:</Text>
  </View>
  <View style={[styles.row2, styles.cell3, styles.borderBottom]}>
    <Text style={[styles.cell, styles.cell3, {textAlign: 'center'}]}>{destiny}</Text>
  </View>
</View>


      <View style={styles.row}>
        <View style={[styles.row, styles.cell1]}>
          <Text style={[styles.cell, styles.cell1, styles.negrita]}>NIT:</Text>
        </View>
        <View style={[styles.row, styles.cell5, styles.borderBottom]}>
          <Text style={[styles.cell, styles.cell5]}>4234234242342</Text>
        </View>
        <View style={[styles.row, styles.cell6]}>
          <Text style={[styles.cell, styles.cell6, styles.negrita]}>B/L No:</Text>
        </View>
        <View style={[styles.row, styles.cell7, styles.borderBottom]}>
          <Text style={[styles.cell, styles.cell7]}>{bLNumber}</Text>
        </View>
      </View>
    </View>
  );
};

export default CargoInformation;