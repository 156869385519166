import React from 'react';
import { View, Text, StyleSheet, Document, Page, Image} from '@react-pdf/renderer';
import { ApplicationModelData } from '../../applicationModelModal/ViewPDF';
import TableApplicationodelIn1 from './ApplicationodelIn1';
import TableApplicationodelIn2 from './ApplicationodelIn2';

export interface originData2 {
  shippedBy: string;
  date: string;
  totalCost: number;
  bLNumber: string;
  info: string;
  origin: string;
  destiny: string;
  contryName: string;
  flete: number;
  sure: number;
  totalImportFOB: number;
  bill: string;
  products: {
    name: string;
    details: Array<{
      tariffCode: string;
      description: string;
      internalCode: string;
      price: number;
      quantity: number;
      import: number;
    }>;
  };
}



const styles = StyleSheet.create({
    tableContainer: {
        width: '88%',
        borderCollapse: 'separate',
        borderSpacing: 0,
        marginBottom: 10,
        marginLeft:'30px',
        marginTop: '20px'
      },
      row: {
        flexDirection: 'row',
        height: 15,
        borderTopWidth: 1,
        borderTopColor: '#000',
      },
      row2: {
        flexDirection: 'row',
        height: 30,
        borderTopWidth: 1,
        borderTopColor: '#000',
      },
      row3: {
        flexDirection: 'row',
        height: 105,
        borderTopWidth: 1,
        borderTopColor: '#000',
      },
      row4: {
        flexDirection: 'row',
        height: 45,
        borderTopWidth: 1,
        borderTopColor: '#000',
      },
      cell: {
          flex: 1,
          paddingTop: 4,
          borderLeftWidth: 1,
          borderLeftColor: '#000',
          borderRightWidth: 1,
          borderRightColor: '#000',
          textAlign: 'left',
          fontSize: 8,
          marginLeft:2
        },
        cellArriba1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '30%',
          },
          cellArriba2: {
            position: 'absolute',
            left: '30%',
            top: 0,
            width: '70%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center'
        },
        cell31: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '35%',
          },
          cell32: {
            position: 'absolute',
            left: '35%',
            top: 0,
            width: '30%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        cell33: {
            position: 'absolute',
            left: '65%',
            top: 0,
            width: '35%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        cell41: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '15%',
          },
          cell42: {
            position: 'absolute',
            left: '15%',
            top: 0,
            width: '35%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        cell43: {
            position: 'absolute',
            left: '50%',
            top: 0,
            width: '15%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        cell44: {
            position: 'absolute',
            left: '65%',
            top: 0,
            width: '35%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '5%',
          },
          doble2: {
            position: 'absolute',
            left: '5%',
            top: 0,
            width: '30%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble3: {
            position: 'absolute',
            left: '35%',
            top: 0,
            width: '20%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble4: {
            position: 'absolute',
            left: '55%',
            top: 0,
            width: '10%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble5: {
            position: 'absolute',
            left: '65%',
            top: 0,
            width: '12.5%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble6: {
            position: 'absolute',
            left: '77.5%',
            top: 0,
            width: '5%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble7: {
            position: 'absolute',
            left: '82.5%',
            top: 0,
            width: '5%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        doble8: {
            position: 'absolute',
            left: '87.5%',
            top: 0,
            width: '12.5%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        bottomRow: {
          borderBottomWidth: 1,
          borderBottomColor: '#000',
        },
        cellXXX1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '80%',
          },
          cellXXX2: {
            position: 'absolute',
            left: '80%',
            top: 0,
            width: '20%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        cellXX1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '65%',
          },
          cellXX2: {
            position: 'absolute',
            left: '65%',
            top: 0,
            width: '35%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left'
        },
        borderLeft: {
          borderLeft: 'none',
        }
})
const ApplicationModelPDF = ({abf,bill,contract,date,destiny,email,entityAddress,entityCode,entityName,exhibit,fullName,gabl,info,objectiveApplication,origin,passport,phone,position,products,rad,supplement}: ApplicationModelData) => {  

    
  return (
    <Document>
      <Page>
        
        <View>
            <Text>
                ANEXO 1 Modelo de Solicitud
            </Text>
        </View>

        <View style={styles.tableContainer}>
        <View style={styles.row3}>
          <View>
            <Text style={styles.cellArriba1}>OBJETIVO DE LA SOLICITUD [Marca con una X]
          </Text>
          <View style={[styles.cellArriba2, {padding: 0}]}>
            <TableApplicationodelIn1/>
          </View>
          </View>
                  </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, {textAlign: "center"}]}>DOCUMENTO(S) DE REFERNCIA</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.cell31}>Contrato S/C {contract}</Text>
          <Text style={[styles.cell32, styles.borderLeft]}>Anexo: {exhibit}</Text>
          <Text style={[styles.cell33, styles.borderLeft]}>Suplemento: {supplement}</Text>
        </View>
        <View style={[styles.row2]}>
          <Text style={styles.cell41}>Facturas: {bill}</Text>
          <Text style={[styles.cell42, styles.borderLeft]}>G.A./BL: {gabl}</Text>
          <Text style={[styles.cell43, styles.borderLeft]}>ABF: {abf}</Text>
          <Text style={[styles.cell44, styles.borderLeft]}>RAD: {rad}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, {textAlign: "center"}]}>Relación de los Equipos de uso final de la enegía / Equipos de generación con fuentes renovables de la enería</Text>
        </View>
        <View style={[styles.row2]}>
          <Text style={styles.doble1}>No.</Text>
          <Text style={[styles.doble2, styles.borderLeft]}>Descripción</Text>
          <Text style={[styles.doble3, styles.borderLeft]}>Subpartida Arancelaria</Text>
          <Text style={[styles.doble4, styles.borderLeft]}>Marca</Text>
          <Text style={[styles.doble5, styles.borderLeft]}>Modelo</Text>
          <Text style={[styles.doble6, styles.borderLeft]}>Cant.</Text>
          <Text style={[styles.doble7, styles.borderLeft]}>Pot. (W)</Text>
          <Text style={[styles.doble8, styles.borderLeft]}>Valor Total</Text>
        </View>
        <View style={[styles.row2]}>
          <Text style={styles.doble1}>x</Text>
          <Text style={[styles.doble2, styles.borderLeft]}>X</Text>
          <Text style={[styles.doble3, styles.borderLeft]}>X</Text>
          <Text style={[styles.doble4, styles.borderLeft]}>X</Text>
          <Text style={[styles.doble5, styles.borderLeft]}>x</Text>
          <Text style={[styles.doble6, styles.borderLeft]}>X</Text>
          <Text style={[styles.doble7, styles.borderLeft]}>X</Text>
          <Text style={[styles.doble8, styles.borderLeft]}>X</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={styles.cell}>País de procedencia {origin}</Text>
          <Text style={[styles.cell, styles.borderLeft]}>País de origen {origin}</Text>
          <Text style={[styles.cell, styles.borderLeft]}>Aduana de despacho {destiny}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, {textAlign: "center"}]}>DATOS SOLICITANTE</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell]}>Nombre de la entidad: {entityName}</Text>
          <Text style={[styles.cell, styles.borderLeft]}>Código de la entidad: {entityCode}</Text>
        </View>
        <View style={[styles.row2]}>
          <Text style={styles.cell}>Direccion de la entidad:  {entityAddress}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cellXXX1]}>Nombre y Apellidos:  {fullName}</Text>
          <Text style={[styles.cellXXX2, styles.borderLeft]}>Firma:</Text>
        </View>
        <View style={[styles.row4]}>
         <View style={[styles.cellXXX1]}>
           <TableApplicationodelIn2/>
         </View>
          <View style={[styles.borderLeft, styles.cellXX2]}> 
            <Text style={[styles.cellXXX2, styles.borderLeft]}>Cuño de la entidad</Text>
          </View>
          </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, {textAlign: "center"}]}>PARA SER LLENADO POR LA ONURE</Text>
        </View>
        <View style={[styles.row, styles.bottomRow]}>
          <Text style={[styles.cellXX1,]}>Registro de etrada:</Text>
          <Text style={[styles.cellXX2, styles.borderLeft]}>Fecha de entrada:</Text>
        </View>
      </View>

      </Page>
    </Document>
  );
};

export default ApplicationModelPDF;