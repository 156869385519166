import React, { useState, createContext } from "react";
import { useForm, SubmitHandler, Control } from "react-hook-form";
import StepsComponent from "../../components/misc/StepsComponent";
import Fetching from "../../components/misc/Fetching";
import DeclarationForm from "./Form1";
import ProductForm from "../../containers/products/newProductModal/ProductForm";
import ProductImg from "../../containers/products/newProductModal/ProductImg";
import DeclarationForm2 from "./Form2";
import ViewPDF, { ApplicationModelData } from "./ViewPDF";
import AplicationForm3 from "./Form3";
import AplicationForm1 from "./Form1";
import AplicationForm4 from "./Form4";
import AplicationForm2 from "./Form2";
import useServerLoad from "../../api/useServerLoad";


interface WizardInterface {
  action: Function;
  closeModal: Function;
  loading: boolean;
}

interface ContextData {
  control?: Control;
  stepUp?: Function;
  stepDown?: Function;
  watch: Function;
  unregister: Function;
}

const contextData: ContextData = { watch: () => {}, unregister: () => {} };

export const ProductContext = createContext(contextData);

const NewWizardContainerAplication = ({
  action,
  closeModal,
  loading,
}: WizardInterface) => {
  const { control, handleSubmit, reset, watch, unregister } =
    useForm<Record<string, string | number>>();

  //Step Component Data-------------------------------------------------------------
  const [currentStep, setCurrentStep] = useState(0);
  const stepTitles = ["Objetivo solcitud", "Documentos de referencia","Relación equipos","Datos del solicitante", "Visualizar PDF"];

  const stepUp = () => setCurrentStep(currentStep + 1);
  const stepDown = () => {
    setCurrentStep(currentStep - 1);
  };
  //----------------------------------------------------------------------------------------
  const [dataResponse, setDataResponse] = useState<ApplicationModelData>()
  const {applicationModel} = useServerLoad()
  //Form Handle -----------------------------------------------------------------------------
    const onSubmit: SubmitHandler<any> = async (data) => {
      try {
        if (currentStep === 4){
          const responseData = await applicationModel({
          fullName: data.fullName,
          email: data.email,
          phone: data.phone,
          multiBuyedReceipt: data.multiBuyedReceipt,
          date: data.date,
          exhibit: data.exhibit,
          contract: data.contract,
          supplement: data.supplement,
          bill: data.bill,
          rad: data.rad,
          abf: data.abf,
          gabl: data.gabl,
          entityName: data.entityName,
          entityAddress: data.entityAddress,
          entityCode: data.entityCode,
          passport: data.passport,
          position: data.position
        }, () => {});
        console.log(data, 'xxx')
        console.log(responseData, 'JJJ')
        setDataResponse(responseData);
        
      }
      stepUp(); 
      if (currentStep === 3) {action(data, closeModal()); console.log('**');}
      
      } catch (error) {
        console.error("Error:", error);
      }
    };

  //-----------------------------------------------------------------------------------------

  return (
    <>
      <StepsComponent current={currentStep} titles={stepTitles} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ProductContext.Provider
          value={{ control, stepUp, stepDown, watch, unregister }}
        >
          {loading && <Fetching />}
          {currentStep === 0 && <AplicationForm3 />}
          {currentStep === 1 && <AplicationForm1 />}
          {currentStep === 2 && <AplicationForm4 />}
          {currentStep === 3 && <AplicationForm2/>}
          {currentStep === 4 && <ViewPDF abf={dataResponse!.abf} bill={dataResponse!.bill} contract={dataResponse!.contract}
          date={dataResponse!.date} destiny={dataResponse!.destiny} email={dataResponse!.email} entityAddress={dataResponse!.entityAddress}
          entityCode={dataResponse!.entityCode} entityName={dataResponse!.entityName} exhibit={dataResponse!.exhibit} fullName={dataResponse!.fullName}
          gabl={dataResponse!.gabl} info={dataResponse!.info} objectiveApplication={dataResponse!.objectiveApplication}
          origin={dataResponse!.origin} passport={dataResponse!.passport} phone={dataResponse!.phone} position={dataResponse!.position}
          products={dataResponse!.products} rad={dataResponse!.rad} supplement={dataResponse!.supplement} />}  
        </ProductContext.Provider>
      </form>
    </>
  );
};

export default NewWizardContainerAplication;