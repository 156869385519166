import { View, Text, StyleSheet } from "@react-pdf/renderer";

export interface TableProps{
  sure: number,
  totalCost: number,
  totalImportFOB:number,
  flete: number,
  products:[{
    item:[{
      tariffCode: string;
        description: string;
        internalCode: string;
        price: number;
        quantity: number;
        import: number;
    }]
    name: string
  }]
}


const TableComercialInvoice = ({flete, products,sure,totalCost, totalImportFOB}: TableProps) => {

    const styles = StyleSheet.create({
        tableContainer: {
          width: '88%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
          marginLeft:'35px',
          marginTop: '10px'
        },
        row: {
          flexDirection: 'row',
          height: 20,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        row2: {
          flexDirection: 'row',
          height: 10,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
            height: '100%'
          },
          cellx: {
            flex: 1,
            paddingTop: 1,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            fontSize: 6,
            height: '100%'
          },
          celly: {
            flex: 1,
            paddingTop: 0.5,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            fontSize: 6,
            height: '100%'
          },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }, 
          border: {
            border: 'none'
          },cell1: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell2: {
            position: 'absolute',
            left: '10%',
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell3: {
            position: 'absolute',
            left: '20%',
            top: 0,
            width: '30%', 
            height: '100%',
            textAlign: 'center'
          },
          cell4: {
            position: 'absolute',
            left: '50%',
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'center'
          },
          cell5: {
            position: 'absolute',
            left: '60%',
            top: 0,
            width: '5%', 
            height: '100%',
            textAlign: 'center'
          },
          cell6: {
            position: 'absolute',
            left: '65%',
            top: 0,
            width: '10%',
            height: '100%',
            textAlign: 'right'
          },
          cell7: {
            position: 'absolute',
            left: '75%',
            top: 0,
            width: '10%', 
            height: '100%',
            textAlign: 'center',
            alignItems: 'center',
            alignContent: 'center'
          },
          cell8: {
            position: 'absolute',
            left: '85%',
            top: 0,
            width: '15%', 
            height: '100%',
            textAlign: 'center'
          },
          cell9: {
            position: 'absolute',
            left: '0%',
            top: 0,
            width: '85%', 
            height: '100%',
            textAlign: 'right'
          },
          cell10: {
            position: 'absolute',
            left: '85%',
            top: 0,
            width: '15%', 
            height: '100%',
            textAlign: 'right'
          },
          cell11: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '75%',
            height: '100%',
            textAlign: 'center'
          },
          cell12: {
            position: 'absolute',
            left: '75%',
            top: 0,
            width: '25%',
            height: '100%',
            textAlign: 'center'
          },

          flexColumn: {
            flexDirection: 'column',
            alignItems: 'center',
          },
          textTop: {
            textAlign: 'center',
            marginTop: 2,
          },
          textBottom: {
            textAlign: 'center',
            marginTop: 2,
          }
    });
  
    return (
      <View style={styles.tableContainer}>
        <View style={styles.row}>
            <View style={[styles.cell, styles.cell1]}>
              <Text style={[styles.cell,styles.border, ]}>Item</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell2]}>
              <Text style={[styles.cell, styles.border, ]}>Codigo</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell3]}>
              <Text style={[styles.cell, styles.border, ]}>Descripción de la marca</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell4]}>
              <Text style={[styles.cell, styles.border, ]}>Referecia</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell5]}>
              <Text style={[styles.cell, styles.border,]}>UM</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell6]}>
              <Text style={[styles.cell, styles.border,]}>Cantidad</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell7]}>
              <Text style={[styles.cell, styles.border,]}>Precio Unitario</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell8]}>
              <Text style={[styles.cell, styles.border,]}>importe</Text>
            </View>  
        </View>

        {products.map((product, index) => (
        <>
        <View style={[styles.row]}>
          <View style={[styles.cell,styles.cell11]}>
            <Text style={[styles.cell, styles.border]}>{product.name}</Text>
          </View>
          <View style={[styles.cell, styles.cell12, styles.borderLeft]}>
            <Text style={[styles.cell, styles.border]}>CIF PUERTO MARIEL en USD</Text>
          </View>
        </View>
        {product.item.map((detail, detailIndex) => (
        <View key={`${product.name}-${index}`} style={[ styles.row]}>
        <View style={[styles.cell, styles.cell1]}>
              <Text style={[styles.cell,styles.border, ]}>{detailIndex + 1}</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell2]}>
              <Text style={[styles.cell, styles.border, ]}>{detail.tariffCode}</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell3]}>
              <Text style={[styles.cell, styles.border, ]}>{detail.description}</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell4]}>
              <Text style={[styles.cell, styles.border, ]}>{detail.internalCode}</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell5]}>
              <Text style={[styles.cell, styles.border,]}>U</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell6]}>
              <Text style={[styles.cell, styles.border,]}>{detail.quantity}</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell7]}>
              <Text style={[styles.cell, styles.border,]}>{detail.price}</Text>
            </View>
            <View style={[styles.cell, styles.borderLeft, styles.cell8]}>
              <Text style={[styles.cell, styles.border,]}>{detail.import}</Text>
            </View>  
        </View>
))}
  </>
))} 

        
      
        

        <View style={[styles.row2]}>
                <View style={[styles.cellx, styles.cell9]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}>Importe Total CIF Puerto Mariel:</Text>
                </View>
                <View style={[styles.cellx, styles.borderLeft, styles.cell10]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}>{totalCost}</Text>
                </View>
            </View>


            <View style={[styles.row2]}>
                <View style={[styles.cellx, styles.cell9]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}>Desgloce Importe CIF</Text>
                </View>
                <View style={[styles.cellx, styles.borderLeft, styles.cell10]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}></Text>
                </View>
            </View>


            <View style={[styles.row2]}>
                <View style={[styles.cellx, styles.cell9]}>
                <Text style={[styles.cellx, styles.border, {marginRight:2}]}>Flete 1x40HQ</Text>
                </View>
                <View style={[styles.cellx, styles.borderLeft, styles.cell10]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}>{flete}</Text>
                </View>
            </View>


            <View style={[styles.row2]}>
                <View style={[styles.cellx, styles.cell9]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}>Seguro:</Text>
                </View>
                <View style={[styles.cellx, styles.borderLeft, styles.cell10]}>
                <Text style={[styles.cellx, styles.border, { marginRight:2}]}>{sure}</Text>
                </View>
            </View>


            <View style={[styles.row2, styles.bottomRow]}>
                <View style={[styles.celly, styles.cell9]}>
                <Text style={[styles.celly, styles.border, { marginRight:2}]}>Importe Total FOB</Text>
                </View>
                <View style={[styles.celly, styles.borderLeft, styles.cell10]}>
                <Text style={[styles.celly, styles.border, { marginRight:2}]}>{totalImportFOB}</Text>
                </View>
            </View>
      </View>
    );
  };

export default TableComercialInvoice