import { View, Text, StyleSheet } from "@react-pdf/renderer";


const TableApplicationodelIn2 = () => {
    const styles = StyleSheet.create({
        tableContainer: {
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
        },
        row: {
          flexDirection: 'row',
          height: 15,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
          },
          cell1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'left',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '40%',
            marginLeft:2
          },
          cell2: {
            position: 'absolute',
            left: '40%',
            top: 0,
            width: '25%',
            height: '100%',
            textAlign: 'center',
            borderRightWidth: 1,
          borderRightColor: '#000',
          marginLeft:2
        },
        cell3: {
          position: 'absolute',
          left: '65%',
          top: 0,
          width: '35%',
          height: '100%',
          textAlign: 'center',
          borderRightWidth: 1,
        borderRightColor: '#000',
        marginLeft:2
      },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }
    });
  
    return (
      <View style={[styles.tableContainer,]}>
        <View style={styles.row}>
          <Text style={[styles.cell, styles.cell1]}>{`Cargo:`}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, ]}>{`Pasaporte:`}</Text>
          <Text style={[styles.cell,styles.cell3, styles.borderLeft, ]}>{`Cargo:`}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>{`Email:`}</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft,]}>{`Teléfono:`}</Text>
          <Text style={[styles.cell,styles.cell3, styles.borderLeft, ]}>{`Email:`}</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell]}>{`Fecha de solicitud:`}</Text>
        </View>
      </View>
    );
  };

export default TableApplicationodelIn2;