import { useContext } from "react";
import { useAppSelector } from "../../store/hooks";
import { ProductContext } from "./wizardContainer";
import { SelectInterface } from "../../interfaces/InterfacesLocal";
import Input from "../../components/forms/Input";

import TextArea from "../../components/forms/TextArea";
import Button from "../../components/misc/Button";
import { PDFViewer } from "@react-pdf/renderer";
import DeclaratePDF from "../components/pdf/DeclaratePDF";
import ApplicationModelPDF from "../components/pdf/ApplicationModelPDF";

export interface ApplicationModelData {
  objectiveApplication: string;
  exhibit: string;
  contract: string;
  rad: string;
  supplement: string;
  abf: string;
  gabl: string;
  bill: string;
  passport: string;
  position: string;
  date: string;
  fullName: string;
  email: string;
  phone: string;
  entityAddress: string;
  entityCode: string;
  entityName: string;
  info: string;
  origin: string;
  destiny: string;
  products: [{
    name: string;
    details: Array<{
      tariffCode: string;
      description: string;
      quantity: number;
      import: number;
      model: string;
      brand: string;
      pow: string;
    }>
  }];
}

const ViewPDF = ({abf, bill,contract,date,destiny,email,entityAddress,entityCode,entityName,exhibit,fullName,gabl,info,objectiveApplication,origin,passport,phone,position,products,rad,supplement}:ApplicationModelData) => {
  
  const { control, stepDown, watch } = useContext(ProductContext);

  return (
    <div>
    <div className="w-full max-w-[1920px] mx-auto mb-8 px-6">
   <PDFViewer>
    <ApplicationModelPDF abf={abf!} bill={bill!} contract={contract!} date={date!} destiny={destiny!}
    email={email!} entityAddress={entityAddress!} entityCode={entityCode!} entityName={entityName!}
    exhibit={exhibit!} fullName={fullName!} gabl={gabl!} info={info!} objectiveApplication={objectiveApplication!}
    origin={origin!} passport={passport!} phone={phone!} position={position!} products={products!} rad={rad!} supplement={supplement!}/>
  </PDFViewer> 
</div>
<div className="grid grid-cols-2 gap-3 py-2 mt-4">
  <Button
    color="slate-500"
    action={stepDown}
    name="Atrás"
    full
    outline
    textColor="slate-600"
  />
  <Button color="slate-600" type="submit" name="Siguiente" full />
</div>
      
    </div>
  );
};

export default ViewPDF;
