import { useContext, useEffect, useState } from "react";
import { ProductContext } from "./wizardContainerDeclaration";
import Input from "../../components/forms/Input";
import Button from "../../components/misc/Button";
import useServerArea from "../../api/useServerArea";
import { CargoData } from "../CertificateofOrigin";
import MultiSelect from "../../components/forms/Multiselect";
import DateInput from "../../components/forms/DateInput";
import Check from "../../components/forms/GenericCheck";
import AsyncComboBox from "../../components/forms/AsyncCombobox";
import TextArea from "../../components/forms/TextArea";



const DeclarationForm2 = () => {
  
  
//   const [dataResponse, setDataResponse] = useState<originData>()
  const {fetchCargos} = useServerArea()
  const [cargosData, setCargosData] = useState<CargoData[]>([]);
  const { control, stepDown, watch } = useContext(ProductContext);
  

   useEffect(() => {
        fetchCargos()
          .then(items => {
            // Manejar el éxito, por ejemplo:
            setCargosData(items);
          })
          .catch(error => {
            // Manejar el error
            console.error('Error al cargar cargos:', error);
          });
      }, []);
      
      const [xData, setXData] = useState({
        imputs: false,
        commercialSamples: false,
        promotions: false
      });

      const handleCheckChange = (value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setXData(prev => ({
          ...prev,
          [value]: isChecked
        }));
      };
      


  return (
    <>
      <div className="h-96 border border-slate-300 rounded p-2 overflow-y-visible">

      <div className="flex space-x-8">
  <div>
  <Check
          value="imputs"
          label="Insumos"
          onChange={(checked) => handleCheckChange('imputs', checked)}
        />
      </div>
      <div>
        <Check
          value="commercialSamples"
          label="Muestras Comerciales"
          onChange={(checked) => handleCheckChange('commercialSamples', checked)}
        />
      </div>
      <div>
        <Check
          value="promotions"
          label="Promociones"
          onChange={(checked) => handleCheckChange('promotions', checked)}
        />
  </div>
</div>
        <div className="grid grid-cols-2 mt-4 gap-3">
          
            <MultiSelect
                name="multiBuyedReceipt"
                data={cargosData}
                label="Cargas/Contenedores (*)"
                control={control}
                rules={{ required: "Campo requerido" }}
            /> 

          <Input
            name="numberPackages"
            label="Cantidad de bulttos"
            control={control}
            type="number"
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
        <AsyncComboBox
              label="Almacen destino (*)"
              name="store"
              control={control}
              dataQuery={{
                url: '/administration/area',
                defaultParams: { type: 'STOCK' },
                searchCriteria: 'q', // Esto se usará para filtrar si se agrega una función de búsqueda
              }}
              normalizeData={{
                id: "id",
                name: "name"
              }}
              rules={{ required: "Este campo es requerido" }}
            />

        <DateInput
            name="date"
            label="Fecha"
            control={control}
            defaultValue={new Date()}
            fromToday
        /> 
        </div>
        <TextArea
                label="Dirección del Almacen"
                name="addressArea"
                control={control}
                rules={{ required: "Este campo es requerido" }}
              />
      </div>
      
      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Generar PDF" full />
      </div>
    </>
  );
};

export default DeclarationForm2;