import { View, Text, StyleSheet } from "@react-pdf/renderer";

interface TableProps {
  topRight: string;
  bottomRight: string;
}

const TwoByTwoTable = ({ topRight, bottomRight }: TableProps) => {
    const styles = StyleSheet.create({
        tableContainer: {
          width: 200,
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
          marginLeft:'355px',
          marginTop: '130px'
        },
        row: {
          flexDirection: 'row',
          height: 15,
          borderTopWidth: 1,
          borderTopColor: '#000',
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
          },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }
    });
  
    return (
      <View style={styles.tableContainer}>
        <View style={styles.row}>
          <Text style={styles.cell}>Factura No</Text>
          <Text style={[styles.cell, styles.borderLeft]}>{topRight}</Text>
        </View>
        <View style={[styles.row, styles.bottomRow]}>
          <Text style={styles.cell}>Fecha</Text>
          <Text style={[styles.cell, styles.borderLeft]}>{bottomRight}</Text>
        </View>
      </View>
    );
  };

export default TwoByTwoTable;