/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import Button from '../components/misc/Button';
import {  SubmitHandler, useForm } from 'react-hook-form';
import AsyncComboBox from '../components/forms/AsyncCombobox';
import Input from '../components/forms/Input';
import DateInput from '../components/forms/DateInput';
import { originData } from './components/pdf/CertificateofOriginPDF'
import { PDFDownloadLink,  PDFViewer } from '@react-pdf/renderer';
import useServerLoad from '../api/useServerLoad';
import CertificateOriginPDF from './components/pdf/CertificateofOriginPDF';
import MultiSelect from '../components/forms/Multiselect';
import useServerArea from '../api/useServerArea';
import DeclaratePDF from './components/pdf/DeclaratePDF';
import ApplicationModelPDF from './components/pdf/ApplicationModelPDF';
import Img from './components/pdf/img';



export interface CertificateOriginData {
  multiBuyedReceipt: number[]
    bill: number;
    date: Date;
  }
  export interface CargoData {
    id: string;
    name: string;
  }

const CertificateofOriginReport = () => {
    

    //React Hook Form-----------------------------------------------------------------------
    const { handleSubmit, control } = useForm();
    const {certificateOrigenM} = useServerLoad()
    const [dataResponse, setDataResponse] = useState<originData>()
    const {fetchCargos} = useServerArea()
    const [cargosData, setCargosData] = useState<CargoData[]>([]);
  
    useEffect(() => {
      fetchCargos()
        .then(items => {
          // Manejar el éxito, por ejemplo:
          setCargosData(items);
        })
        .catch(error => {
          // Manejar el error
          console.error('Error al cargar cargos:', error);
        });
    }, []);


      const onSubmit: SubmitHandler<any> = async (data) => {
         try {
           const responseData = await certificateOrigenM({
            multiBuyedReceipt: data.multiBuyedReceipt,
            bill: data.bill,
            date: data.date
          }, () => {});
           
          setDataResponse(responseData)
           console.log(responseData,'algo');
           console.log(data, 'hola')
         } catch (error) {
           console.error("Error:", error);
         }
      };
    

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-xl font-semibold mb-6">
                    Complete la información del Certificado de Origen
                </h2>
                <div className="flex flex-col gap-2 w-full">
                     <MultiSelect
                    name="multiBuyedReceipt"
                    data={cargosData}
                    label="Cargas/Contenedores (*)"
                    control={control}
                    rules={{ required: "Campo requerido" }}
                    />    
                    <div>
                    <div className="flex-col">
                        <Input
                            label="Factura"
                            name="bill"
                            control={control}
                            rules={{ required: "Este campo es requerido" }}
                            type='number'
                        />
                    </div>
                    </div>

                    
                    <div className="flex flex-col gap-2 w-full">
                        <DateInput
                        name="date"
                        label="Fecha"
                        control={control}
                        defaultValue={new Date().getDate()}
                        fromToday
                        />    
                    </div>    
                   {
                  dataResponse && dataResponse !== null &&
                   <PDFViewer>
                           <CertificateOriginPDF bill={dataResponse.bill} 
                        products={dataResponse.products}
                        date={dataResponse.date}
            />    
              {/* <Img/>  
               */}
                    </PDFViewer>} 
                    <div className="w-full flex justify-end gap-3 mt-4">
                    
                <div>
                  <Button
                    color="slate-600"
                    textColor="slate-600"
                    type="submit"
                    name="Enviar datos al Formulario"
                    outline
                    //action={() => {
                    //  setShowModal(false);
                    //}}
                  />
                </div>
               {dataResponse && dataResponse !== null && <PDFDownloadLink
            document={ 
            // <Img/>
             <CertificateOriginPDF bill={dataResponse.bill} 
             products={dataResponse.products}
             date={dataResponse.date}
             />
          }
            fileName="Certificado_de_Origen.pdf"
            style={{ backgroundColor: '#364954', color: '#FFFFFF', padding: '5px 10px', borderRadius: '0.375rem' }}
          >
            Descargar PDF
          </PDFDownloadLink>}
              </div></div>

            </form>

            
        </div>
    )
}

export default CertificateofOriginReport


