import { Text, View, StyleSheet, Image} from '@react-pdf/renderer';


interface SideBySideTextProps {
  rightText: string;
}

const styles = StyleSheet.create({
    sideBySideTextContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 60, // Ajusta este valor según sea necesario
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: 5,
    },
    leftTextContainer: {
      position: 'relative',
      width: '55%',
      height: '100%',
      marginRight: 5,
      padding: '0px',
    },
    leftTextTop: {
      position: 'absolute',
      top: 0,
      left: 0,
      fontSize: 7,
      lineHeight: 8,
      wordBreak: 'break-word',
      textAlign: 'left',
      maxWidth: '100%',
      marginTop: '90px',
      marginBottom: 0,
      marginLeft: '70px',
    },
    leftTextBottom: {
        position: 'absolute',
        bottom: 0, // Cambiado de 0 a 5 para moverlo un poco más arriba
        right: 0,
        fontSize: 7,
        wordBreak: 'break-word',
        textAlign: 'right',
        maxWidth: '100%',
        marginTop: '80px',
        marginRight: '70px',
        top:20
      },
    rightText: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '40%',
      fontSize: 12,
      color: '#c40000',
      marginTop: '80px',
    },
    itemImage: {
      width: '10%',
      height: 'auto',
      resizeMode: 'contain',
      marginBottom: 5,
    },
});
console.log()

const SideBySideTextComponent = ({ rightText }: SideBySideTextProps) => (
    <View style={styles.sideBySideTextContainer}>
    <View style={styles.leftTextContainer}>
    
      <Text style={styles.leftTextTop}>Rooms 2006-8 20/F, Two Chinachem Exchange Square, 338</Text>
      <Text style={styles.leftTextBottom}>King's Road, North Point, Hong Kong</Text>
    </View>
    <Text style={styles.rightText}>{rightText}</Text>
  </View>
  
);

export default SideBySideTextComponent;


// sideBySideTextContainer: {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: 60, // Ajusta este valor según sea necesario
//   flexDirection: 'row',
//   justifyContent: 'flex-start',
//   alignItems: 'flex-start',
//   marginBottom: 5,
// },
// leftTextContainer: {
//   position: 'relative',
//   width: '55%',
//   height: '100%',
//   marginRight: 5,
//   padding: '0px',
// },
// leftTextTop: {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   fontSize: 7,
//   lineHeight: 8,
//   wordBreak: 'break-word',
//   textAlign: 'left',
//   maxWidth: '100%',
//   marginTop: '90px',
//   marginBottom: 0,
//   marginLeft: '70px',
// },
// leftTextBottom: {
//     position: 'absolute',
//     bottom: 0, // Cambiado de 0 a 5 para moverlo un poco más arriba
//     right: 0,
//     fontSize: 7,
//     wordBreak: 'break-word',
//     textAlign: 'right',
//     maxWidth: '100%',
//     marginTop: '80px',
//     marginRight: '70px',
//     top:20
//   },
// rightText: {
//   position: 'absolute',
//   top: 0,
//   right: 0,
//   width: '40%',
//   fontSize: 12,
//   color: '#c40000',
//   marginTop: '80px',
// }