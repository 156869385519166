import { View, Text, StyleSheet } from "@react-pdf/renderer";

const OriginDeclaration = () => {
  const styles = StyleSheet.create({
    container: {
      marginTop: 5,
      textAlign: 'center',
      fontSize: 10.5,
      marginLeft: 30,
      marginRight: 30,
      padding: 10,
    },
  });

  return (
    <View style={styles.container}>
      <Text>
        Nosotros, el vendedor, declaramos por la presente que todos los productos mencionados a continuación fueron originarios de China y que cumplen con las Reglas de Origen de la República Popular de China.
      </Text>     
    </View>
  );
};

export default OriginDeclaration;