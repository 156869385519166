import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { ProductContext } from "./wizardContainer";
import { SelectInterface } from "../../interfaces/InterfacesLocal";
import Input from "../../components/forms/Input";
import Select from "../../components/forms/Select";
import CurrencyInput from "../../components/forms/CurrencyInput";
import InputDuration from "../../components/forms/InputDuration";
import Toggle from "../../components/forms/Toggle";
import TextArea from "../../components/forms/TextArea";
import Button from "../../components/misc/Button";
import useServerArea from "../../api/useServerArea";
import { CargoData } from "../CertificateofOrigin";
import MultiSelect from "../../components/forms/Multiselect";
import DateInput from "../../components/forms/DateInput";
import Check from "../../components/forms/GenericCheck";


const AplicationForm2 = () => {
  
  const { control, stepDown, watch } = useContext(ProductContext);
   

  return (
    <>
      <div className="h-96 border border-slate-300 rounded p-2 overflow-y-visible">

          <Input
            name="entityCode"
            label="Código de la entidad"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />

          <Input
            name="fullName"
            label="Nombre y apellidos"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="Corgo"
            name="email"
            type="text"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
          <Input
            label="Pasaporte"
            name="passport"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <Input
            label="Correo electónico"
            name="email"
            type="text"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
          <Input
            label="Teléfono"
            name="phone"
            type="number"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>

      </div>

      <div className="grid grid-cols-2 gap-3 py-2">
        <Button
          color="slate-500"
          action={stepDown}
          name="Atrás"
          full
          outline
          textColor="slate-600"
        />
        <Button color="slate-600" type="submit" name="Siguiente" full />
      </div>
    </>
  );
};

export default AplicationForm2;