import { View, Text, StyleSheet } from "@react-pdf/renderer";

interface TableProps {
  topRight: string;
  bottomRight: string;
}

const TableUpPacking = () => {
    const styles = StyleSheet.create({
        tableContainer: {
          width: 200,
          borderCollapse: 'separate',
          borderSpacing: 0,
          marginBottom: 10,
          marginLeft:'150px',
          marginTop: '20px'
        },
        row: {
          flexDirection: 'row',
          height: 15,
          borderTopWidth: 1,
          borderTopColor: '#000',
          borderRightWidth: 1,
          borderRightColor: '#000',
        },
        cell: {
            flex: 1,
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
          },
          cell1: {
            position: 'absolute',
            paddingTop: 4,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '70%',
          },
          cell2: {
            position: 'absolute',
            left: '70%',
            top: 0,
            width: '30%',
            height: '100%',
            textAlign: 'left',
            borderRight: 'none'
        },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }
    });
  
    return (
      <View style={[styles.tableContainer,]}>
        <View style={styles.row}>
          <Text style={[styles.cell, styles.cell1]}>Cantidad de contenedores:</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>X40HQ</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>Total de cartones:</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>Cartones</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>Total G.W:</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>KGS</Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[styles.cell, styles.cell1]}>Total N.W:</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>KGS</Text>
        </View>
        <View style={[styles.row, styles.bottomRow]}>
          <Text style={[styles.cell, styles.cell1]}>Cubicaje Total:</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft, {marginLeft:2}]}>sadas</Text>
        </View>
      </View>
    );
  };

export default TableUpPacking;