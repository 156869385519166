import { View, Text, StyleSheet } from "@react-pdf/renderer";



const TableInPacking = () => {
    const styles = StyleSheet.create({
      tableContainer: {
        width: '100%',
        height: 25,
        borderBottomWidth: 0,
        borderTopWidth: 0,
    },
        row: {
          flexDirection: 'row',
          height: 25,
        },
        cell: {
            flex: 1,
            paddingTop: 5,
            borderLeftWidth: 1,
            borderLeftColor: '#000',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
          },
          cell1: {
            position: 'absolute',
            paddingTop: 4,
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center',
            fontSize: 8,
            height: '100%',
            left: 0,
            top: 0,
            width: '15%',
          },
          cell2: {
            position: 'absolute',
            left: '15%',
            top: 0,
            width: '15%',
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: '#000',
            textAlign: 'center'
        },
        cell3: {
          position: 'absolute',
          left: '30%',
          top: 0,
          width: '40%',
          height: '100%',
          borderRightWidth: 1,
          borderRightColor: '#000',
          textAlign: 'center'
      },
      cell4: {
        position: 'absolute',
        left: '70%',
        top: 0,
        width: '20%',
        height: '100%',
        borderRightWidth: 1,
        borderRightColor: '#000',
        textAlign: 'center'
    },
    cell5: {
      position: 'absolute',
      left: '90%',
      top: 0,
      width: '10%',
      height: '100%',
      textAlign: 'center'
  },
          bottomRow: {
            borderBottomWidth: 1,
            borderBottomColor: '#000',
          },
          borderLeft: {
            borderLeft: 'none',
          }
    });
  
    return (
      <View style={styles.tableContainer}>
        <View style={styles.row}>
          <Text style={[styles.cell, styles.cell1, styles.borderLeft]}>Cantidad de contenedores:</Text>
          <Text style={[styles.cell,styles.cell2, styles.borderLeft]}>X40HQ</Text>
          <Text style={[styles.cell,styles.cell3, styles.borderLeft]}>X40HQ</Text>
          <Text style={[styles.cell,styles.cell4, styles.borderLeft]}>X40HQ</Text>
          <Text style={[styles.cell,styles.cell5, styles.borderLeft, {borderRight:'none'}]}>X40HQ</Text>
        </View>
      </View>
    );
  };

export default TableInPacking;