import React from 'react';
import { View, Text, StyleSheet, Document, Page, Image } from '@react-pdf/renderer';
import SideBySideTextComponent from './SideBySideTextComponent';
import TwoByTwoTable from './TwoByTwoTable';
import OriginDeclaration from './OriginDeclaration';
import TableCerti from './TableCertifi';
import CargoInformation from './CargoInformation';
import TableComercialInvoice from './TableCommercialInvoice';
import TablepackingList from './TablepackingList';
import TableinContainer from './TableinContainer';

export interface originData2 {
  shippedBy: string;
  date: string;
  totalCost: number;
  bLNumber: string;
  info: string;
  origin: string;
  destiny: string;
  contryName: string;
  flete: number;
  sure: number;
  totalImportFOB: number;
  bill: string;
  products:[{
    item:[{
      tariffCode: string;
        description: string;
        internalCode: string;
        price: number;
        quantity: number;
        import: number;
    }]
    name: string
  }]
}



const styles = StyleSheet.create({
  itemImage: {
      width: 12,
      height: 12,
      resizeMode: 'contain',
      marginBottom: 5,
    },
})
const ComercialInvoicePDF = ({bill, date, products, bLNumber, contryName, destiny,flete, info,origin, shippedBy, sure,totalCost,totalImportFOB}: originData2 ) => {
  
  const rightText = `FACTURA COMERCIAL`

  

  

  return (
    <Document>
      <Page>
      <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
      {/* Your existing content */}
      
    <View style={{ marginTop: 20 }}>
        <SideBySideTextComponent 
            rightText={rightText} 
        />
    </View>
    <View>
            <TwoByTwoTable 
                topRight={bill}
                bottomRight={date}
        />
    </View>
    
    <View>
    <CargoInformation bLNumber={bLNumber}
    origin={origin}
    destiny={destiny}
    shippedBy={shippedBy}
    info={info} />
    </View> 

      {/* <View>
      <TableinContainer/>
      </View> */}

     <View>
      <TableComercialInvoice flete={flete}
      products={products}
      sure={sure}
      totalCost={totalCost}
      totalImportFOB={totalImportFOB}/>
    </View> 
    
    
    
    
   
    {/* <View>
    <Image
  src="../../../assets/logo/banner+logo.png" style={styles.itemImage}  break={true}
/>
    </View> */}
    
    </View>
      </Page>
    </Document>
  );
};

export default ComercialInvoicePDF;