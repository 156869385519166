import { useContext } from "react";
import { useAppSelector } from "../../store/hooks";
import { ProductContext } from "./wizardContainerDeclaration";
import { SelectInterface } from "../../interfaces/InterfacesLocal";
import Input from "../../components/forms/Input";

import TextArea from "../../components/forms/TextArea";
import Button from "../../components/misc/Button";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import DeclaratePDF from "../components/pdf/DeclaratePDF";

export interface SwordDeclarate{
  date: string,
  addressArea: string,
  store: number,
  fullName: string,
  noCustomsRegistration: number,
  email: string,
  phone: number,
  numberPackages: number,
  businessName: string,
  businessAddress: string,
  name: string
  products: Array<
    {
      price: number,
      priceCodeCurrency?: string,
      quantity: number,
      description?: string,
      totalValue: number
    }>
  }
  
  


const styles = StyleSheet.create({
  container:{
    width: '100%',
  height: '300px'
  }
})

const ViewPDF = ({addressArea, date, name,businessAddress, businessName,email, fullName,noCustomsRegistration,numberPackages,phone,products,store}: SwordDeclarate) => {
  console.log(date, noCustomsRegistration, 'Estoy dentro del pdf')
  
  const { control, stepDown, watch } = useContext(ProductContext);

  return (
    <div className="h-96 border border-slate-300 rounded p-2 overflow-y-auto">
    <PDFViewer style={styles.container}>
      <DeclaratePDF addressArea={addressArea!} name={name!} businessAddress={businessAddress!}
      businessName={businessName!} date={date!} email={email!} fullName={fullName!}
      noCustomsRegistration={noCustomsRegistration!} numberPackages={numberPackages!}
      phone={phone!} products={products!} store={store!}/>
    </PDFViewer>
    
    <div className="mt-4 flex justify-between items-center pt-4">
      <Button 
        color="slate-500" 
        action={stepDown} 
        name="Atrás" 
        full 
        outline 
        textColor="slate-600"
      />
      <Button 
        color="slate-600" 
        type="submit" 
        name="Siguiente" 
        full 
      />
    </div>
  </div>
  );
};

export default ViewPDF;
