/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import Breadcrumb, { PathInterface } from '../../../../components/navigation/Breadcrumb'
import { CubeIcon } from '@heroicons/react/24/outline'
import GenericTable, { DataTableInterface } from '../../../../components/misc/GenericTable';
import Modal from '../../../../components/misc/GenericModal';
import GrossSalesByContainersReport from '../../../../reports/GrossSalesByContainersReport';
import ProductInventoryPerContainerReport from '../../../../reports/ProductInventoryPerContainerReport';
import CertificateofOriginReport from '../../../../reports/CertificateofOrigin';
import CommercialPackingListReport from '../../../../reports/CommercialPackingList';
import CommercialNoPackingListReport from '../../../../reports/CommercialNoPackingList';
import CommercialInvoiceReport from '../../../../reports/CommercialInvoice';
import CommercialNoInvoiceReport from '../../../../reports/CommercialNoInvoice';
import NewWizardContainerDeclaration from '../../../../reports/swornDeclarationModal/wizardContainerDeclaration';
import useServerLoad from '../../../../api/useServerLoad';
import NewWizardContainerAplication from '../../../../reports/applicationModelModal/wizardContainer';



const CargoAnalisis = ({ breadcrumb = true }) => {
//Todo hacer todo como esta aqui, un state en false en las misma carpeta dende tienen los otros modales 
    const [grossSalesByContainers, setGrossSalesByContainers] = useState<boolean>(false)
    const [productInventoryPerContainer, setProductInventoryPerContainer] = useState<boolean>(false)
    const [certificateofOriginContainer, setCertificateofOriginContainer] = useState<boolean>(false)
    const [commercialPackingListContainer, setCommercialPackingList] = useState(false)
    const [commercialNoPackingListContainer, setCommercialNoPackingList] = useState(false)
    const [commercialInvoiceContainer,setCommercialInvoice] = useState(false)
    const [commercialNoInvoiceContainer, setCommercialNoInvoice] = useState(false)
    const [newWizardContainerDeclaration, setNewWizardContainerDeclaration] = useState(false)
    const [newWizardContainerAplication, setNewWizardContainerAplication] = useState(false)
    const {swornDeclarationM, applicationModel} = useServerLoad()


    const paths: PathInterface[] = [
        {
            name: "Cargas",
        },
        {
            name: "Análisis",
        },
    ];

    const reportsType = [
        // {
        //     id: 2,
        //     name: "Venta bruta por contenedores",
        // },
        {
            id: 3,
            name: "Inventario de productos por contenedor",
        },
        {
            id: 4,
            name: "Modelo Solicitud",
        },
        {
            id: 5,
            name: "Certificado de Origen",
        },
        {
            id: 6,
            name: "Declaracion Jurada",
        },
        {
            id: 7,
            name: "Factura Comercial",
        },
        {
            id: 8,
            name: "Factura no Comercial",
        },
        {
            id: 9,
            name: "Lista de Empaque Comercial",
        },
        {
            id: 10,
            name: "Lista de Empaque no Comercial",
        },

    ];

    const tableData: DataTableInterface[] = [];
    reportsType.map(({ id, name }) => {
        tableData.push({
            rowId: id,
            payload: {
                "Nombre del reporte": name,
            },
        });
    });

    // Agregar cada uno aqui para que los state esten en verdadro y se abran los modales con sus respectivos modales 
    // Row table actions
    const rowAction = (id: number) => {
        // if (id === 2) {
            // setGrossSalesByContainers(true);
        // }
        if (id === 3) {
            setProductInventoryPerContainer(true);
        }
        if (id === 4) {
            setNewWizardContainerAplication(true);
        }
        if (id === 5){
            setCertificateofOriginContainer(true)
        }
        if (id === 6){
            setNewWizardContainerDeclaration(true)
        }
        if (id === 7){
            setCommercialInvoice(true)
        }
        if (id === 8){
            setCommercialNoInvoice(true)
        }
        if (id === 9){
            setCommercialPackingList(true)
        }
        if (id === 10){
            setCommercialNoPackingList(true)
        }
    };

    //Data to dislay in table ---------------------------------------------------------------------------
    const tableTitle: string[] = ["Nombre del reporte"];


    return (
        <div>
            {breadcrumb && (
                <Breadcrumb
                    icon={<CubeIcon className="h-7 text-gray-500" />}
                    paths={paths}
                />
            )}

            <GenericTable
                tableData={tableData}
                tableTitles={tableTitle}
                rowAction={rowAction}
            />

            {
                grossSalesByContainers && (
                    <Modal state={grossSalesByContainers} close={() => setGrossSalesByContainers(false)}>
                        <GrossSalesByContainersReport />
                    </Modal>
                )
            }

            {
                productInventoryPerContainer && (
                    <Modal state={productInventoryPerContainer} close={() => setProductInventoryPerContainer(false)}>
                        <ProductInventoryPerContainerReport />
                    </Modal>
                )
            }
            {
                certificateofOriginContainer && (
                    <Modal state={certificateofOriginContainer} close={() => setCertificateofOriginContainer(false)}>
                        <CertificateofOriginReport />
                    </Modal>
                )
            }
             {
                newWizardContainerDeclaration && (
                    <Modal state={newWizardContainerDeclaration} close={() => setNewWizardContainerDeclaration(false)} size="m">
                        <NewWizardContainerDeclaration 
                            action={swornDeclarationM}
                            closeModal={() => setNewWizardContainerDeclaration(false)}
                            loading={false}
                
            />                </Modal>
                )
            }
            {
                newWizardContainerAplication && (
                    <Modal state={newWizardContainerAplication} close={()=> setNewWizardContainerAplication(false)} size="m">
                        <NewWizardContainerAplication
                            action={applicationModel}
                            closeModal={() => setNewWizardContainerDeclaration(false)}
                            loading={false}/>
                    </Modal>
                )
            } 
            {
                commercialPackingListContainer && (
                    <Modal state= {commercialPackingListContainer} close={()=> setCommercialPackingList(false)}>
                        <CommercialPackingListReport/>
                    </Modal>
                 )
            }
            {
                commercialNoPackingListContainer && (
                    <Modal state= {commercialNoPackingListContainer} close={()=> setCommercialNoPackingList(false)}>
                        <CommercialNoPackingListReport/>
                    </Modal>
                 )
            }
            {
                commercialInvoiceContainer && (
                    <Modal state = {commercialInvoiceContainer} close={()=> setCommercialInvoice(false)}>
                        <CommercialInvoiceReport/>
                    </Modal>
                )
            }
            { 
                commercialNoInvoiceContainer && (
                    <Modal state = {commercialNoInvoiceContainer} close={()=> setCommercialNoInvoice(false)}>
                        <CommercialNoInvoiceReport/>
                    </Modal>
                )
            } 
        </div>
    )
}

export default CargoAnalisis



