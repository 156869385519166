import React from 'react';
import { View, Text, StyleSheet, Document, Page, Image } from '@react-pdf/renderer';
import SideBySideTextComponent from './SideBySideTextComponent';
import TwoByTwoTable from './TwoByTwoTable';
import OriginDeclaration from './OriginDeclaration';
import TableCerti from './TableCertifi';
import CargoInformation from './CargoInformation';
import TableComercialInvoice from './TableCommercialInvoice';
import TablepackingList from './TablepackingList';
import TableinContainer from './TableinContainer';

export interface originData{
  bill: string,
  date: string,
  products:[{
    item:[{
      description: string,
      internalCode: string,
      quantity: number,
      tariffCode: string
    }]
    name: string
  }]
}

const styles = StyleSheet.create({
  itemImage: {
      width: 12,
      height: 12,
      resizeMode: 'contain',
      marginBottom: 5,
    },
})
const CertificateOriginPDF = ({bill, date, products}: originData ) => {
  
  const rightText = `CERTIFICADO DE ORIGEN`

  

  

  return (
    <Document>
      <Page>
      <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
      {/* Your existing content */}
      
    <View style={{ marginTop: 20 }}>
        <SideBySideTextComponent 
            rightText={rightText} 
        />
    </View>
    <View>
            <TwoByTwoTable 
                topRight={bill}
                bottomRight={date}
        />
    </View>
    <View>
        <OriginDeclaration />
    </View>
    <View>
        <TableCerti products={products}/>
    </View>  


       {/* <View>
        <TablepackingList/>
      </View>  */}

      {/* <View>
      <TableinContainer/>
      </View> */}

    {/* <View>
      <TableComercialInvoice/>
    </View> */}
    
    
    
    {/* <View>
    <CargoInformation topLeft={topLeft}
                topRight={topRight}
                bottomLeft={bottomLeft}
                bottomRight={bottomRight}/>
    </View> */}
   
    {/* <View>
    <Image
  src="../../../assets/logo/banner+logo.png" style={styles.itemImage}  break={true}
/>
    </View> */}
    
    </View>
      </Page>
    </Document>
  );
};

export default CertificateOriginPDF;
